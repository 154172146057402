import './index.less';

import vsf from '@vs/vsf-boot';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';

import low from '@/assets/low.png';
import tall from '@/assets/tall.png';

import Empty from '../Empty';
interface UserInfoProps {
  data?: any[];
  type?: 'noReadReport' | 'abnorma';
  patientId?: number;
  indicatorType?: IndicatorTypeEnum | undefined;
}

const Index: React.FC<UserInfoProps> = (props) => {
  const { data, type = 'abnorma', patientId, indicatorType } = props;
  const renderImg = (value) => {
    return (
      value != 'Z' && (
        <img src={value == 'H' ? tall : low} className="tallImg"></img>
      )
    );
  };
  const onRowClick = (v) => {
    if (v.reportResource && type === 'abnorma') {
      vsf?.navigateTo(
        `/report?id=${v?.id}&reportList=${JSON?.stringify(data?.filter((item) => item?.reportId)?.map((v) => v?.reportId))}&planPatientId=${patientId}`,
      );
    }
    if (v.testReportId && type === 'noReadReport') {
      vsf?.navigateTo(
        `/report?id=${v.testReportId}&reportList=${JSON?.stringify(data?.filter((item) => item?.testReportId)?.map((v) => v?.testReportId))}&planPatientId=${patientId}`,
      );
    }
  };
  return (
    <div className="report_table">
      <table>
        <thead className="second-header">
          <tr>
            <th>
              {type === 'abnorma'
                ? indicatorType !== 'LATEST'
                  ? '测量日期'
                  : '报告日期'
                : '检验项目'}
            </th>
            <th>结果</th>
            {indicatorType !== 'DIARY' && <th>参考范围</th>}
            <th>单位</th>
          </tr>
        </thead>
        <tbody className="second-content">
          {data?.map((v, i) => {
            return (
              <tr
                className="second-item"
                key={'second' + i}
                onClick={() => onRowClick(v)}
              >
                <td>
                  <div className="item1 ellipsis2">
                    {type === 'abnorma'
                      ? dayjs(v?.reportTime)?.format('YYYY-MM-DD')
                      : v?.testItemName}
                  </div>
                </td>
                <td
                  className={classNames(`time2 ellipsis1`, {
                    abnormalV: v?.abnormal,
                  })}
                >
                  <span>
                    {type === 'abnorma' ? v?.elementValue : v?.testResultValue}
                    {v?.resultStatus && renderImg(v?.resultStatus)}
                  </span>
                </td>
                {indicatorType !== 'DIARY' && (
                  <td className="item3">{v?.referenceRanges}</td>
                )}
                <td className="item4">
                  {type === 'abnorma'
                    ? v?.normalValueUnit
                    : v?.testResultValueUnit}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {!data?.length && <Empty type="health_care" text="暂无数据" />}
    </div>
  );
};
export default Index;
