import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { NavBar } from '@vs/vsf-mobile';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import DomTitle from '@/components/dom_title';
import Empty from '@/components/Empty';
import IndicatorBottom from '@/components/indicator-bottom';
import PatientHeader from '@/components/patient-header';
import { PERSONTYPE, READSTAUS } from '@/config';

const Index: React.FC<any> = (props) => {
  const { planId, patientId } = props.routes.query;
  const [data, setData] = useState<SpecialistGuidanceVo765641[]>([]);
  const [currentPlanId, setCurrentPlanId] = useState<number | undefined>(
    planId ? Number(planId) : undefined,
  );
  const getListSpecialistGuidanceVoByPlanMasterId = useCallback(async () => {
    const res =
      await vsf.services?.SpecialistGuidanceController_getListSpecialistGuidanceVoByPlanMasterId_f198c8(
        {
          planMasterId: currentPlanId,
        },
      );
    if (res.code === 200) {
      setData(res.data);
    }
  }, [currentPlanId]);

  const handelOk = async (str: string) => {
    const res =
      await vsf?.services?.SpecialistGuidanceController_createSpecialistGuidance_990ad4(
        {
          guidanceBto: {
            planMasterId: currentPlanId,
            content: str,
            guidanceType: 'SPECIAL_GUIDANCE',
          },
        },
      );
    if (res?.code === 200) {
      getListSpecialistGuidanceVoByPlanMasterId();
      return true;
    }
    return false;
  };
  useEffect(() => {
    getListSpecialistGuidanceVoByPlanMasterId();
  }, [getListSpecialistGuidanceVoByPlanMasterId]);
  const renderCard = (data: SpecialistGuidanceVo765641) => {
    return (
      <div className="card" key={data?.id}>
        <div className="header">
          <div className="type">
            {data?.guidanceType ? PERSONTYPE[data?.guidanceType] : '-'}
          </div>
          <div
            className={classNames([
              'status',
              {
                init: data?.readStatus === 'UNREAD',
                read: data?.readStatus === 'READ',
              },
            ])}
          >
            {data?.readStatus ? READSTAUS[data?.readStatus] : '-'}
          </div>
        </div>
        <div className="card-content">
          <div className="card-item">
            <div className="card-label">处理意见</div>
            <div className="card-text">{data?.content ?? '-'}</div>
          </div>
          <div className="card-item">
            <div className="card-label">处理医生</div>
            <div className="card-text">{data?.doctor?.doctorName ?? '-'}</div>
          </div>
          <div className="card-item">
            <div className="card-label">处理时间</div>
            <div className="card-text">{data?.createdAt ?? '-'}</div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <NavBar children="专病指导" backArrow={false} />
      <DomTitle title="专病指导" />
      <div className="guide_container">
        <PatientHeader
          patientId={patientId}
          planId={currentPlanId}
          onChange={(val) => setCurrentPlanId(val)}
        />
        {data?.length ? (
          <>
            <div className="content">
              {data?.map((item) => renderCard(item))}
            </div>
          </>
        ) : (
          <Empty type="health_care" text="暂无数据" />
        )}
        <IndicatorBottom showLeft={false} onOk={handelOk} />
      </div>
    </>
  );
};

export default definePage(Index);
