import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { NavBar, Steps } from '@vs/vsf-mobile';
import { useThrottleFn } from 'ahooks';
import { RightOutline } from 'antd-mobile-icons';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import DomTitle from '@/components/dom_title';
import Empty from '@/components/Empty';
import IndicatorBottom from '@/components/indicator-bottom';
import IndicatorHeader from '@/components/indicator-header';
import IndicatorItem from '@/components/indicator-item';
import UserInfoCard from '@/components/userInfoCard';
const { Step } = Steps;
const IndicatorDetails: React.FC<any> = (props) => {
  const { type, planId, elementId } = props.routes.query;
  const [currentHeader, setCurrentHeader] =
    useState<PlanIndicatorMonitoringWithWarnVoDe76C9>();
  const [flag, setFlag] = useState<boolean>(false);
  const [data, setData] = useState<
    PlanMasterPatientAbnormalElementVoDe76C9 & PlanMasterPatientReportVoDe76C9
  >();
  const getElementId = useCallback(
    (v) => {
      const elementId =
        type === 'abnorma' ? v?.projectKey : '' + v?.id + v?.reportResource;
      return elementId;
    },
    [type],
  );
  const getPlanTestIndicator = useCallback(async () => {
    const res =
      await vsf?.services?.RagPlanController_getPlanTestIndicator_2f84be({
        planMasterId: planId,
      });
    if (res?.code === 200) {
      setData(res?.data);
    }
  }, [planId]);

  const getPlanNoReadReportDetail = useCallback(async () => {
    const res =
      await vsf?.services?.RagPlanController_getPlanNoReadReportDetail_b93848({
        planMasterId: planId,
      });
    if (res?.code === 200) {
      setData({ ...res?.data, planIndicatorMonitoring: res.data?.planReport });
    }
  }, [planId]);

  const guidanceType = useMemo(() => {
    return type === 'abnorma'
      ? 'ABNORMAL_INDICATOR'
      : type === 'noReadReport'
        ? 'NEW_REPORT'
        : '';
  }, [type]);
  const guidanceDetailBtoList = useMemo(() => {
    if (type === 'abnorma') {
      return data?.planIndicatorMonitoring
        ?.map((item) =>
          item.abnormalId?.map((a) => ({
            indicatorType: 'LATEST',
            detailId: a,
            planMasterId: planId,
          })),
        )
        ?.flat();
    }
    if (type === 'noReadReport') {
      return data?.planReport?.map((item) => ({
        indicatorType: item.reportResource ? 'REPORT' : 'DIARY',
        detailId: item.id,
        planMasterId: planId,
      }));
    }
  }, [data, type, planId]);
  const handelOk = async (str: string) => {
    const res =
      await vsf?.services?.SpecialistGuidanceController_createSpecialistGuidance_990ad4(
        {
          guidanceBto: {
            planMasterId: planId,
            content: str,
            guidanceType,
          },
          guidanceDetailBtoList,
        },
      );
    if (res?.code === 200) {
      vsf.goBack();
      return true;
    }
    return false;
  };

  const handleCancel = async () => {
    const res =
      await vsf?.services?.SpecialistGuidanceController_createSpecialistGuidance_990ad4(
        {
          guidanceDetailBtoList,
        },
      );
    if (res?.code === 200) {
      vsf.goBack();
      return true;
    }
    return false;
  };

  const toReport = (value) => {
    console.log('value', value);
    vsf?.navigateTo(
      `/doctor/patient_report?planId=${planId}&patientId=${data?.patient?.id}&projectName=${value?.projectName}`,
    );
  };

  const onHeaderChange = useCallback(
    (v) => {
      document.getElementById(`anchor-${getElementId(v)}`)?.scrollIntoView();
      setCurrentHeader(v);
      setFlag(true);
      setTimeout(() => {
        setFlag(false);
      }, 300);
    },
    [setCurrentHeader, getElementId],
  );
  useEffect(() => {
    if (type === 'abnorma') {
      getPlanTestIndicator();
    }
    if (type === 'noReadReport') {
      getPlanNoReadReportDetail();
    }
  }, [getPlanTestIndicator, type, getPlanNoReadReportDetail]);
  const mainElementRef = useRef<HTMLDivElement>(null);
  const { run: handleScroll } = useThrottleFn(
    () => {
      let current = data?.planIndicatorMonitoring?.[0];

      for (const item of data?.planIndicatorMonitoring ?? []) {
        const element = document.getElementById(`anchor-${getElementId(item)}`);
        if (!element) continue;
        const rect = element.getBoundingClientRect();
        if (rect.top <= 130) {
          current = item;
        } else {
          break;
        }
      }
      !flag && setCurrentHeader(current);
    },
    {
      leading: true,
      trailing: true,
      wait: 100,
    },
  );
  useEffect(() => {
    if (!data?.planIndicatorMonitoring) return;
    const mainElement = mainElementRef.current;
    if (!mainElement) return;
    mainElement.addEventListener('scroll', handleScroll);
    return () => {
      mainElement.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, data?.planIndicatorMonitoring, getElementId]);

  useEffect(() => {
    if (data?.planIndicatorMonitoring?.length) {
      if (elementId) {
        const element = data?.planIndicatorMonitoring?.find(
          (item) => getElementId(item) === elementId,
        );
        if (element) {
          onHeaderChange(element);
        } else {
          onHeaderChange(data?.planIndicatorMonitoring?.[0]);
        }
      } else {
        onHeaderChange(data?.planIndicatorMonitoring?.[0]);
      }
    }
  }, [elementId, data?.planIndicatorMonitoring, getElementId, onHeaderChange]);
  return (
    <>
      <NavBar children="异常指标" backArrow={false} />
      <DomTitle title={type === 'abnorma' ? '异常指标' : '新出报告'} />
      <div className="health_care_anomaly-container">
        <div className="top">
          <div className="fixed-top">
            <UserInfoCard
              patient={data?.patient}
              currentPlanPathway={data?.currentPlanPathway}
            />
            <IndicatorHeader
              type={type}
              headerList={data?.planIndicatorMonitoring}
              currentHeader={currentHeader}
              onClick={onHeaderChange}
              onChange={onHeaderChange}
            />
          </div>
        </div>
        {data?.planIndicatorMonitoring?.length ? (
          <div
            className="health_care_anomaly-step-container"
            ref={mainElementRef}
          >
            <Steps direction="vertical">
              {data?.planIndicatorMonitoring?.map((item) => (
                <Step
                  key={getElementId(item)}
                  title={
                    <div className="health_care_anomaly_title">
                      <span
                        className="anchor"
                        id={`anchor-${getElementId(item)}`}
                      ></span>
                      <span>{item?.projectName ?? '-'}</span>
                      <span
                        onClick={() => toReport(item)}
                        className="health_care_anomaly_history"
                      >
                        {`历史报告`}
                        <RightOutline />
                      </span>
                    </div>
                  }
                  description={
                    <IndicatorItem
                      type={type}
                      data={item}
                      patientId={data?.patient?.id}
                    />
                  }
                />
              ))}
            </Steps>
            <IndicatorBottom onCancel={handleCancel} onOk={handelOk} />
          </div>
        ) : (
          <Empty type="health_care" text="暂无数据" />
        )}
      </div>
    </>
  );
};

export default definePage(IndicatorDetails);
