import { createServices } from '@vs/vsf-boot';
export default createServices({
  RagPlanController_getDoctorPlanMasterMonitoringExecute_157279: {
    method: 'post',
    url: '/api/rag-plan/get-doctor-plan-master-monitoring-execute',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: PlanMasterMonitoringExecuteVoDe76C9[]) =>
      data,
  },
});
