// CaseManagement
import './index.less';

import useUrlState from '@ahooksjs/use-url-state';
import vsf, { definePage } from '@vs/vsf-boot';
import { NavBar, SearchBar } from '@vs/vsf-mobile';
import { useThrottleFn } from 'ahooks';
import { Badge, Picker, Toast } from 'antd-mobile';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import abnormal from '@/assets/abnormal.png';
import empty from '@/assets/empty.png';
import triangle from '@/assets/Slice49.png';
import DomTitle from '@/components/dom_title';
import Empty from '@/components/Empty';
import { GenderEnum, planStatus } from '@/config';

const size = 20;

interface CaseCard {
  value?: any;
  key?: number;
  type?: string;
  tag?: string;
  onClickDetail?: (e) => void;
  onClickType?: (e) => void;
  onClickIndividual?: (e) => void;
}

interface CasePicker {
  title?: string;
  value?: any;
  visible?: boolean;
  columns?: any;

  onClose?: () => void;
  onConfirm?: (value) => void;
  onClick?: () => void;
}

const CaseCard: React.FC<CaseCard> = (props) => {
  const { value, type, tag, onClickDetail, onClickType, onClickIndividual } =
    props;
  const { currentPlanPathway, currentPlanStage, doctor, order, team, patient } =
    value;
  return (
    <div
      className="CaseCard"
      onClick={(e) => {
        e.stopPropagation();
        onClickDetail && onClickDetail(value);
      }}
    >
      <div className="CaseCard-header">
        <div className="left">
          <div className="text name">{patient?.patientName}</div>
          <div className="text">{GenderEnum?.[patient?.patientGender]}</div>
          <div className="text">
            {patient.birthday
              ? dayjs().year() - dayjs(patient.birthday).year()
              : '-'}
            岁
          </div>
        </div>
        {type == 'all' && value?.indicatorMonitoring?.length > 0 && (
          <div
            className="right"
            onClick={(e) => {
              e.stopPropagation();
              onClickType && onClickType(value);
            }}
          >
            <img className="ietm-img" src={abnormal}></img>
            <div className="item-title">异常指标</div>
          </div>
        )}
      </div>
      <div className="CaseCard-content">
        <div className="info-item">
          <div className="left">路径阶段</div>
          <div className="right">
            {currentPlanPathway?.title}
            {`(${currentPlanStage?.title ?? '-'})`}
          </div>
        </div>
        <div className="info-item">
          <div className="left">医生/团队</div>
          <div className="right">{doctor?.doctorName ?? team?.teamName}</div>
        </div>
      </div>
      {type == 'all' ? null : (
        <div className="case-individual">
          {tag === 'abnorma' &&
            value?.indicatorMonitoring?.map((v, index) => {
              return (
                <div
                  className="individual-item"
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickIndividual &&
                      onClickIndividual({
                        ...v,
                        value,
                        elementId: v?.projectKey,
                      });
                  }}
                >
                  {v?.projectName}指标异常
                </div>
              );
            })}
          {tag === 'noReadReport' &&
            value?.noReadReport?.map((v, index) => {
              return (
                <div
                  className="individual-item"
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickIndividual && onClickIndividual({ ...v, value });
                  }}
                >
                  <div className="projectName">{v?.reportName}</div>
                  <div className="reportTime">{v?.reportTime}</div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

const CasePicker: React.FC<CasePicker> = (props) => {
  const {
    title,
    value = [],
    visible,
    columns,
    onClose,
    onConfirm,
    onClick,
  } = props;

  const renderStr = (value, num) => {
    if (value?.length > num) {
      return `${value?.substring(0, num)}...`;
    }
    return value;
  };

  return (
    <>
      <div
        className="CasePicker-contaniner"
        onClick={() => {
          onClick && onClick();
        }}
      >
        <div className="title">{renderStr(value?.label ?? title, 5)}</div>
        <img className="triangle" src={triangle}></img>
      </div>
      <Picker
        columns={[columns]}
        visible={visible}
        onClose={() => {
          onClose && onClose();
        }}
        value={[value?.value]}
        onConfirm={(v) => {
          const selectValie = columns?.find((ietm) => ietm?.value == v?.[0]);
          onConfirm && onConfirm(selectValie);
        }}
      />
    </>
  );
};

const CaseManagement: React.FC<any> = (props) => {
  const [urlstate, setUrlState] = useUrlState(props.routes?.query, {
    navigateMode: 'replace',
  });
  const [stateVisible, setStateVisible] = useState(false);
  const [teamVisible, setTeamVisible] = useState(false);
  const [currentCaseState, setCurrentCaseState] = useState<any>(
    urlstate?.currentCaseState ? JSON?.parse(urlstate?.currentCaseState) : null,
  );

  const [pathVisible, setPathVisible] = useState(false);
  const [currentPathValue, setCurrentPathValue] = useState<any>();
  const [currentTeamValue, setCurrentTeamValue] = useState<any>();

  const [selectList, setSelectList] = useState([]);
  const [selectTeamList, setSelectTeamList] = useState([]);
  const [currentPatientNameIs, setcurrentPatientNameIs] = useState(null);

  const [currentTab, setCurrentTab] = useState<'individual' | 'all'>(
    urlstate?.currentTab ?? 'individual',
  );

  const [list, setList] = useState<PlanMasterMonitoringExecuteVoDe76C9[]>([]);

  const filterList = useMemo(() => {
    let searchList = [...list];
    if (currentPatientNameIs) {
      searchList = searchList.filter((item) =>
        item.patient?.patientName?.includes(currentPatientNameIs),
      );
    }
    if (currentTab === 'individual') {
      searchList = searchList.filter(
        (item) => item.status === 'FINISH' || item.status === 'EXECUTE',
      );
      if (urlstate?.currentTag === 'abnorma')
        return searchList
          .filter((item) => item.indicatorMonitoring?.length)
          .sort((a: any, b: any) => {
            const flag = dayjs(a?.earlyAbnormalTime)?.isBefore(
              dayjs(b?.earlyAbnormalTime),
            );
            return flag ? -1 : 1;
          });
      if (urlstate?.currentTag === 'noReadReport')
        return searchList
          .filter((item) => item.noReadReport?.length)
          .map((item) => ({
            ...item,
            noReadReport: item.noReadReport?.sort(
              (a, b) =>
                dayjs(a.reportTime).valueOf() - dayjs(b.reportTime).valueOf(),
            ),
          }))
          .sort(
            (a, b) =>
              dayjs(a.noReadReport?.[0]?.reportTime).valueOf() -
              dayjs(b.noReadReport?.[0]?.reportTime).valueOf(),
          );
    }
    if (currentTab === 'all') {
      let filterData = [...searchList];
      if (currentCaseState?.value)
        filterData = filterData.filter(
          (item) => item.status === currentCaseState.value,
        );
      if (currentPathValue?.value && currentPathValue?.value !== 'all')
        filterData = filterData.filter(
          (item) => item.currentPlanPathway?.title === currentPathValue.value,
        );
      if (currentTeamValue?.value && currentTeamValue?.value !== 'all')
        filterData = filterData.filter(
          (item) =>
            (item.team?.id || item.doctor?.id) === currentTeamValue.value,
        );
      return filterData;
    }
    return searchList;
  }, [
    list,
    urlstate?.currentTag,
    currentTab,
    currentCaseState,
    currentPathValue,
    currentTeamValue,
    currentPatientNameIs,
  ]);
  const scrollToPosition = () => {
    document.getElementById(`CaseManagement-content`)?.scrollIntoView();
  };
  const abnormaLen = useMemo(() => {
    return (
      list.filter(
        (item) =>
          item.indicatorMonitoring?.length &&
          (item.status === 'FINISH' || item.status === 'EXECUTE'),
      )?.length || null
    );
  }, [list]);
  const noReadReportLen = useMemo(() => {
    return (
      list.filter(
        (item) =>
          item.noReadReport?.length &&
          (item.status === 'FINISH' || item.status === 'EXECUTE'),
      )?.length || null
    );
  }, [list]);

  const getDoctorPlanMasterMonitoringExecute = useCallback(async () => {
    const res =
      await vsf?.services?.RagPlanController_getDoctorPlanMasterMonitoringExecute_157279();
    if (res?.code == 200) {
      setList(res.data);
      let currentTag = urlstate?.currentTag || 'abnorma';
      if (!res.data?.filter((item) => item.indicatorMonitoring?.length)?.length)
        currentTag = 'noReadReport';
      if (!res.data?.filter((item) => item.noReadReport?.length)?.length)
        currentTag = 'abnorma';
      setUrlState({ currentTag });
      const arr = [{ label: '全部', value: null }] as any;
      const arr1 = [{ label: '全部', value: null }] as any;
      res?.data?.forEach((v) => {
        if (!arr.find((item) => item.value === (v?.team?.id || v.doctor?.id))) {
          arr.push({
            ...v,
            label: v?.team?.teamName || v.doctor?.doctorName,
            value: v?.team?.id || v.doctor?.id,
          });
        }
        if (!arr1.find((item) => item.label === v?.currentPlanPathway?.title)) {
          arr1.push({
            ...v,
            label: v?.currentPlanPathway?.title,
            value: v?.currentPlanPathway?.title,
          });
        }
      });
      setSelectTeamList(arr);
      setSelectList(arr1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      if (urlstate?.currentPathValue) {
        setCurrentPathValue(JSON?.parse(urlstate?.currentPathValue));
      }
      if (urlstate?.currentTeamValue) {
        setCurrentTeamValue(JSON?.parse(urlstate?.currentTeamValue));
      }
      if (urlstate?.currentCaseState) {
        setCurrentCaseState(JSON?.parse(urlstate?.currentCaseState));
      } else {
        setCurrentCaseState({
          label: '路径中',
          value: 'EXECUTE',
        });
      }

      if (urlstate?.currentTab) {
        setCurrentTab(urlstate?.currentTab);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    scrollToPosition();
  }, [currentTab, urlstate?.currentTag]);
  useEffect(() => {
    setUrlState({
      currentCaseState: JSON?.stringify(currentCaseState),
      currentPathValue: JSON?.stringify(currentPathValue),
      currentTeamValue: JSON?.stringify(currentTeamValue),
      currentTab: currentTab,
    });
  }, [
    currentCaseState,
    currentPathValue,
    currentTeamValue,
    currentTab,
    setUrlState,
  ]);

  useEffect(() => {
    getDoctorPlanMasterMonitoringExecute();
  }, [getDoctorPlanMasterMonitoringExecute]);

  const handleSearchChange = (value) => {
    setcurrentPatientNameIs(value);
  };

  const { run } = useThrottleFn(handleSearchChange, { wait: 500 });
  const handleClickTab = (value) => {
    setCurrentTab(value);
    if (value == 'individual') {
      setCurrentCaseState({
        label: '路径中',
        value: 'EXECUTE',
      });
      setCurrentPathValue({});
      setCurrentTeamValue({});
    }
    setcurrentPatientNameIs(null);
  };

  return (
    <>
      <NavBar children="个案管理" backArrow={false} />
      <DomTitle title="个案管理" />
      <div className="CaseManagement-container">
        <div className="CaseManagement-header">
          <SearchBar
            placeholder="搜索患者姓名"
            icon={false}
            className="search"
            onChange={run}
          />
          <div className="tab">
            <div
              className={classNames(`tab-item`, {
                activeTab: currentTab == 'individual',
              })}
              onClick={() => {
                handleClickTab('individual');
              }}
            >
              待处理
            </div>
            <div
              className={classNames(`tab-item`, {
                activeTab: currentTab == 'all',
              })}
              onClick={() => {
                handleClickTab('all');
              }}
            >
              所有患者
            </div>
          </div>
          {currentTab == 'all' && (
            <div className="CasePicker-box">
              <CasePicker
                title="个案状态"
                value={currentCaseState}
                visible={stateVisible}
                onClick={() => {
                  setStateVisible(true);
                }}
                onClose={() => {
                  setStateVisible(false);
                }}
                onConfirm={(value) => {
                  setCurrentCaseState(value);
                }}
                columns={planStatus}
              ></CasePicker>
              <CasePicker
                title="医生/团队"
                value={currentTeamValue}
                visible={teamVisible}
                onClick={() => {
                  setTeamVisible(true);
                }}
                onClose={() => {
                  setTeamVisible(false);
                }}
                onConfirm={(value) => {
                  console.log('valuevaluevalue', value);
                  setCurrentTeamValue(!value.value ? {} : value);
                }}
                columns={selectTeamList}
              ></CasePicker>
              <CasePicker
                title="请选择路径"
                value={currentPathValue}
                visible={pathVisible}
                onClick={() => {
                  setPathVisible(true);
                }}
                onClose={() => {
                  setPathVisible(false);
                }}
                onConfirm={(value) => {
                  setCurrentPathValue(!value.value ? {} : value);
                }}
                columns={selectList}
              ></CasePicker>
            </div>
          )}
          {currentTab == 'individual' && (
            <div className="case">
              {abnormaLen && (
                <Badge content={abnormaLen}>
                  <div
                    className={classNames([
                      {
                        ['active-case-item']:
                          urlstate?.currentTag === 'abnorma',
                      },
                      'case-item',
                    ])}
                    onClick={() => setUrlState({ currentTag: 'abnorma' })}
                  >
                    指标异常
                  </div>
                </Badge>
              )}
              {noReadReportLen && (
                <Badge content={noReadReportLen}>
                  <div
                    className={classNames([
                      {
                        ['active-case-item']:
                          urlstate?.currentTag === 'noReadReport',
                      },
                      'case-item',
                      'next',
                    ])}
                    onClick={() => setUrlState({ currentTag: 'noReadReport' })}
                  >
                    新出报告
                  </div>
                </Badge>
              )}
            </div>
          )}
        </div>
        <div className="CaseManagement-content">
          <div
            id="CaseManagement-content"
            style={{ position: 'relative', top: -148 }}
          ></div>
          {filterList?.map((v, index) => (
            <CaseCard
              key={index}
              value={v}
              tag={urlstate?.currentTag}
              onClickDetail={(value) => {
                if (currentTab === 'all') {
                  vsf?.navigateTo(
                    `/doctor/patient_info?planId=${value?.id}&patientId=${value?.patient?.id}`,
                  );
                } else {
                  vsf?.navigateTo(
                    `/doctor/health_care_anomaly?planId=${value?.id}&type=${urlstate?.currentTag}`,
                  );
                }
              }}
              // onClickType={(value) => {
              //   vsf?.navigateTo(
              //     `/cases_detail?planId=${value?.id}&type=WAIT_AUDIT`,
              //   );
              // }}
              onClickIndividual={(value) => {
                const elementId =
                  urlstate?.currentTag === 'abnorma'
                    ? value.projectKey
                    : '' + value.id + value.reportResource;
                vsf?.navigateTo(
                  `/doctor/health_care_anomaly?planId=${value?.value?.id}&elementId=${elementId}&type=${urlstate?.currentTag}`,
                );
              }}
              type={currentTab}
            ></CaseCard>
          ))}
          {filterList?.length == 0 && (
            <div className="my_empty">
              <Empty type="health_care" text="暂无个案"></Empty>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default definePage(CaseManagement);
