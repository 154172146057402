import vsf, { defineStore } from '@vs/vsf-boot';

import storage from '@/utils/storage';
const user = defineStore({
  doctorUserInfo: storage.get('doctor_user') || {},
  patientUserInfo: storage.get('patient_user') || {},
  currentUserInfo: storage.get('current_user') || {},
  initCharts: storage.get('initCharts') || true,
  noticeData: storage.get('notice_data') || 0,
  setDoctorUserInfo: (v) => {
    user.doctorUserInfo = v;
    storage.set('doctor_user', v);
  },
  setPatientUserInfo: (v) => {
    user.patientUserInfo = v;
    storage.set('patient_user', v);
  },
  setCurrentUserInfo: (v) => {
    user.currentUserInfo = v;
    storage.set('current_user', v);
  },
  setInitCharts: (v) => {
    user.initCharts = v;
    storage.set('initCharts', v);
  },
  setNoticeData: (v) => {
    user.noticeData = v;
    storage.set('notice_data', v);
  },
});

export default user;
