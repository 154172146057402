import { createServices } from '@vs/vsf-boot';
export default createServices({
  PlanController_getPatientPlanMaster_1a941b: {
    method: 'post',
    url: '/api/rag-plan/get-patient-plan-master',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (_, __, data?: PlanMasterBaseVoDe76C9[]) => data,
  },
  PlanController_getListPlanMasterPathwayVoByPatientId_395bad: {
    method: 'post',
    url: '/api/rag-plan/get-list-plan-master-pathway-vo-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (_, __, data?: PlanMasterPathwayVoDe76C9[]) => data,
  },
  EmployeePatientController_getPatientManagerVoById_edbb80: {
    method: 'post',
    url: '/api/employee-patient/get-patient-manager-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: PatientManagerVo462A84) => data,
  },
});
