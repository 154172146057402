import './index.less';

import vsf from '@vs/vsf-boot';
import { Button, Popup, TextArea } from '@vs/vsf-mobile';
import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import inputImg from '@/assets/inputImg.png';
import { getENVIR } from '@/utils';

interface HeaderProps {
  showLeft?: boolean;
  onCancel?: () => Promise<boolean>;
  onOk?: (str: string) => Promise<boolean>;
}
const CommonPhrases = [
  `家长您好，根据目前的健康数据，孩子情况良好。请按照医嘱定时给孩子服药，如果有任何不适，请及时联系我们。我们相信，通过我们的共同努力，孩子能够更快地恢复健康。`,
  `家长您好，根据目前的健康数据，孩子情况良好。请让孩子保持良好的生活习惯，如均衡饮食和适量运动，这对孩子的康复非常重要。我们相信，通过我们的共同努力，孩子能够更快地恢复健康。`,
  `家长您好，根据目前的健康数据，孩子情况良好。请记得定期带孩子复查，以便我们能够及时跟踪孩子健康状况井调整管理方案，我们相信，通过我们的共同努力，孩子能够更快地恢复健康。`,
  `根据我们最新的健康数据，我们建议您在接下来的 1-2天内带孩子来医院进行复查、这将有助于我们更准确地评估孩子的健康状况，并据此调整和优化我们的健康管理方案。期待您的配合，共同为孩子的健康保驾护航。谢谢！`,
];
const isWx = getENVIR()?.includes('wx');
const isiOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);

const renderSafeArea = () => {
  return isWx && isiOS ? '22px' : '11px';
};

const Index: React.FC<HeaderProps> = (props) => {
  const { showLeft = true, onOk, onCancel } = props;
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [text, setText] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState(0);
  const textAreaRef = useRef<any>(null);
  const textAreaRef1 = useRef<any>(null);
  const textAreaRef2 = useRef<any>(null);

  // 浏览器的高度 默认设置为0；
  const [height, setHeight] = useState(21);
  const getEle = () => {
    let ele = textAreaRef1?.current;
    if (isiOS) {
      ele = textAreaRef?.current;
    }
    return ele;
  };
  const focusFn = () => {
    const ele = getEle();
    ele?.nativeElement?.setAttribute('readonly', 'readonly');
    setTimeout(() => {
      ele?.nativeElement?.removeAttribute('readonly');
    }, 200);
  };

  const insertPhrase = (phrase) => {
    const ele = getEle();
    const { nativeElement, focus } = ele; // 获取光标位置
    const { selectionStart, selectionEnd, setSelectionRange } = nativeElement; // 获取光标位置
    if (text) {
      const newText =
        text.slice(0, selectionStart) + phrase + text.slice(selectionEnd); // 插入常用语
      setText(newText);
    } else {
      setText(phrase);
    }
    // 更新光标位置
    focus();
    setTimeout(() => {
      nativeElement.setSelectionRange(
        selectionStart + phrase.length,
        selectionStart + phrase.length,
      );
    }, 0);
    !isiOS && focusFn();
  };
  const handelCommonClick = () => {
    if (isiOS) {
      if (!visible1) {
        setVisible1(!visible1);
        textAreaRef?.current?.focus?.();
        const { nativeElement } = textAreaRef.current; // 获取光标位置
        nativeElement.setSelectionRange(selection, selection);
        setTimeout(() => {
          nativeElement.setSelectionRange(selection, selection);
        }, 0);
      } else {
        onSelectionChnage();
        setVisible1(!visible1);
        const { nativeElement } = textAreaRef1.current; // 获取光标位置
        setTimeout(() => {
          nativeElement.setSelectionRange(selection, selection);
        }, 200);
      }
    } else {
      setVisible1(!visible1);
      textAreaRef1?.current?.focus?.();
      !visible1 && focusFn();
    }
  };

  const handelSend = async () => {
    setLoading(true);
    setVisible(false);
    try {
      onOk && (await onOk(text)) && setText(undefined);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      vsf.showToast('操作失败！', 'error');
    }
  };
  const handelCancel = async () => {
    setLoading(true);
    try {
      onCancel && (await onCancel());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      vsf.showToast('操作失败！', 'error');
    }
  };

  const onSelectionClick1 = (e) => {
    if (visible1) {
      e.stopPropagation();
      e.preventDefault();
      setVisible1(!visible1);
      const { nativeElement } = textAreaRef1.current; // 获取光标位置
      setTimeout(() => {
        nativeElement.setSelectionRange(text.length, text.length);
      }, 200);
    }
  };
  const onSelectionChnage = () => {
    setSelection(textAreaRef?.current?.nativeElement?.selectionStart);
  };
  const onSelectionClick = () => {
    if (visible1) {
      setVisible1(false);
      textAreaRef1?.current?.focus?.();
    }
  };
  const onSelectionChnage1 = () => {
    if (isiOS) {
      setSelection(textAreaRef1?.current?.nativeElement?.selectionStart);
    } else {
      if (visible1) {
        setVisible1(false);
        textAreaRef1?.current?.focus?.();
      }
    }
  };
  useEffect(() => {
    if (readOnly && visible1 && visible) {
      setTimeout(() => {
        setReadOnly(false);
      }, 500);
    }
    if (!visible1 || !visible) setReadOnly(true);
  }, [readOnly, visible1, visible]);

  useLayoutEffect(() => {
    setHeight(textAreaRef2?.current?.clientHeight);
  }, [text]);

  return (
    <div className="indicator-bottom">
      <div className="pay_box" style={{ paddingBottom: renderSafeArea() }}>
        {showLeft ? (
          <div className="pay_box_item">
            <Button
              loading={loading}
              shape="rounded"
              block
              className="left"
              onClick={handelCancel}
            >
              暂不回复
            </Button>
          </div>
        ) : null}
        <div className="pay_box_item">
          <Button
            shape="rounded"
            className="right"
            block
            onClick={() => {
              setVisible(true);
            }}
            loading={loading}
          >
            回复建议
          </Button>
        </div>
      </div>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
          setVisible1(false);
        }}
        onClose={() => {
          setVisible(false);
          setVisible1(false);
        }}
      >
        <div className="popup-content">
          <div className="input-content">
            <div className={classNames(['left', { visible: visible1 }])}>
              <img
                src={inputImg}
                className="left_icon"
                alt=""
                onClick={handelCommonClick}
              />
            </div>
            <div className="center" onClick={onSelectionClick1}>
              {isiOS && (
                <TextArea
                  ref={textAreaRef}
                  value={text}
                  onChange={(value) => {
                    setText(value);
                    onSelectionChnage1();
                  }}
                  rows={1}
                  placeholder="输入文本"
                  autoFocus
                  readOnly={readOnly}
                  className={classNames([{ ['visibility-input']: !visible1 }])}
                  style={{
                    height: height,
                    '--color': '#243752',
                    '--font-size': '14px',
                  }}
                  onClick={onSelectionChnage}
                  onFocus={() => textAreaRef.current?.blur()}
                />
              )}
              <TextArea
                ref={textAreaRef1}
                value={text}
                onChange={(value) => {
                  setText(value);
                  onSelectionChnage1();
                }}
                rows={1}
                placeholder="输入文本"
                autoFocus
                className={classNames([
                  { ['visibility-input']: visible1 && isiOS },
                ])}
                style={{
                  height: height,
                  '--color': '#243752',
                  '--font-size': '14px',
                }}
                onClick={onSelectionClick}
              />
              <div ref={textAreaRef2} className="visibility-text">
                {text}
              </div>
            </div>
            <Button
              style={{
                '--background-color': '#1778FF',
                '--border-color': '#1778FF',
              }}
              color="primary"
              className="right"
              size="small"
              onClick={handelSend}
              disabled={!text}
            >
              发送
            </Button>
          </div>
          <div className="common" style={{ maxHeight: visible1 ? '40vh' : 0 }}>
            {CommonPhrases.map((phrase) => (
              <div
                className="common-item"
                key={phrase}
                onClick={() => insertPhrase(phrase)}
              >
                {phrase}
              </div>
            ))}
          </div>
        </div>
      </Popup>
    </div>
  );
};
export default Index;
