import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { NavBar } from '@vs/vsf-mobile';
import React, { useCallback, useEffect, useState } from 'react';

import DomTitle from '@/components/dom_title';
import Empty from '@/components/Empty';
import IndicatorBottom from '@/components/indicator-bottom';
import IndicatorHeader from '@/components/indicator-header';
import IndicatorItem from '@/components/indicator-item';
import UserInfoCard from '@/components/userInfoCard';
const type = 'abnorma';
const IndicatorDetails: React.FC<any> = (props) => {
  const { elementId, patientId, planId, planTitle } = props.routes.query;
  const { plan } = props.stores;
  const { patientPlanTask } = plan;
  const [currentHeader, setCurrentHeader] = useState<any>();
  const [headerList, setHeaderList] = useState<any>([{}, {}, {}, {}, {}]);
  const [currentPatient, setCurrentPatient] = useState<any>();
  const [data1, setData1] = useState<PlanIndicatorMonitoringWithWarnVoDe76C9>(
    {},
  );

  const getPatientManagerVoById = useCallback(async () => {
    const res =
      await vsf.services?.EmployeePatientController_getPatientManagerVoById_edbb80(
        {
          id: patientId,
        },
      );
    if (res.code === 200) {
      setCurrentPatient(res.data);
    }
  }, [patientId]);
  // 查询个案详情--待处理
  const getUnExecuteTasks1 = async () => {
    const data = {
      patientId: patientId,
      projectKey: currentHeader?.elementId,
    } as any;
    if (planId) data.planMasterId = planId;
    const res =
      await vsf.services?.RagPlanController_getPatientIndicatorWithWarnInfo_aca4de(
        data,
      );
    if (res.code === 200) {
      setData1(res.data);
    }
  };
  const getHeaderList = async () => {
    const data = {
      patientId: patientId,
    } as any;
    if (planId) data.planMasterId = planId;
    const res =
      await vsf.services?.RagPlanController_getPatientPlanTaskExecuteElement_552d18(
        data,
      );
    if (res.code === 200) {
      const patientPlanTask = res.data;
      if (elementId) {
        const filterList =
          patientPlanTask
            ?.filter((v) => v?.elementId != elementId)
            ?.sort((a, b) => b?.abnormal - a?.abnormal) ?? [];
        const findObj = patientPlanTask?.find((v) => v?.elementId == elementId);
        setHeaderList([findObj, ...filterList]);
        setCurrentHeader(findObj);
      } else {
        setHeaderList(patientPlanTask);
        setCurrentHeader(patientPlanTask?.[0]);
      }
    }
  };
  useEffect(() => {
    if (patientPlanTask?.length) {
      if (elementId) {
        const filterList =
          patientPlanTask
            ?.filter((v) => v?.elementId != elementId)
            ?.sort((a, b) => b?.abnormal - a?.abnormal) ?? [];
        const findObj = patientPlanTask?.find((v) => v?.elementId == elementId);
        setHeaderList([findObj, ...filterList]);
        setCurrentHeader(findObj);
      } else {
        setHeaderList(patientPlanTask);
        setCurrentHeader(patientPlanTask?.[0]);
      }
    } else {
      getHeaderList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPlanTask, elementId]);
  useEffect(() => {
    if (patientId && currentHeader?.elementId) getUnExecuteTasks1();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, currentHeader?.elementId]);
  useEffect(() => {
    getPatientManagerVoById();
  }, [getPatientManagerVoById]);

  const guidanceType = 'ABNORMAL_INDICATOR';

  const handelOk = async (str: string) => {
    const res =
      await vsf?.services?.SpecialistGuidanceController_createSpecialistGuidance_990ad4(
        {
          guidanceBto: {
            planMasterId: planId,
            content: str,
            guidanceType,
          },
        },
      );
    if (res?.code === 200) {
      vsf.showToast('回复成功', 'success');
      return true;
    }
    return false;
  };

  const onHeaderChange = useCallback(
    (v) => {
      setCurrentHeader(v);
    },
    [setCurrentHeader],
  );
  return (
    <>
      <NavBar children="异常指标" backArrow={false} />
      <DomTitle title="指标记录" />
      <div className="indicator_details-container">
        <div className="top">
          <div className="fixed-top">
            <UserInfoCard
              patient={currentPatient}
              currentPlanPathway={{
                id: planId,
                title: planTitle,
              }}
            />
            <IndicatorHeader
              type="abnorma"
              headerList={headerList}
              currentHeader={currentHeader}
              onClick={onHeaderChange}
              onChange={onHeaderChange}
            />
          </div>
        </div>
        {headerList?.length ? (
          <div className="indicator_details-step-container">
            <div className="i_title">{currentHeader?.projectName}</div>
            <IndicatorItem
              type={type}
              data={data1}
              patientId={patientId}
              key={currentHeader?.elementId}
            />
            <IndicatorBottom showLeft={false} onOk={handelOk} />
          </div>
        ) : (
          <Empty type="health_care" text="暂无数据" />
        )}
      </div>
    </>
  );
};

export default definePage(IndicatorDetails);
