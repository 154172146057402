import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';

import user_avatar_png from '@/assets/user_avatar.png';
import { GenderEnum, PLANSTATUSENUM } from '@/config';
import { cardHide, isImgSrc } from '@/utils';

import ChangePlanPopup from '../changePlanPopup';

interface HeaderProps {
  patientId?: number;
  planId?: number;
  onChange?: (value: number) => void;
}

const Index: React.FC<HeaderProps> = (props) => {
  const { patientId, planId, onChange } = props;
  const [currentPatient, setCurrentPatient] = useState<any>();
  const [currentPlan, setCurrentPlan] = useState<PlanMasterPathwayVoDe76C9>();
  const getPatientManagerVoById = useCallback(async () => {
    const res =
      await vsf.services?.EmployeePatientController_getPatientManagerVoById_edbb80(
        {
          id: patientId,
        },
      );
    if (res.code === 200) {
      setCurrentPatient(res.data);
    }
  }, [patientId]);

  useEffect(() => {
    getPatientManagerVoById();
  }, [getPatientManagerVoById]);
  return (
    <div className="patient-header">
      <div className="avatar">
        <img src={user_avatar_png} alt="" />
      </div>
      <div className="patient-header_content">
        <div className="user_name_box">
          <div className="user_name">{currentPatient?.patientName ?? '-'}</div>
          <div className="user_gender">
            {currentPatient?.patientGender
              ? GenderEnum[currentPatient?.patientGender]
              : '-'}
          </div>
          <div className="user_age">
            {currentPatient?.birthday
              ? dayjs().year() - dayjs(currentPatient?.birthday).year()
              : '-'}
            岁
          </div>
        </div>
        <div className="id_card">{currentPlan?.currentPlanPathway?.title}</div>
      </div>
      <div className="right">
        <ChangePlanPopup
          planId={planId}
          patientId={patientId}
          onChange={(val) => {
            console.log(val);
            setCurrentPlan(val);
            onChange && onChange(val.id);
          }}
        />
      </div>
    </div>
  );
};
export default Index;
