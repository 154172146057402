import './index.less';

import useUrlState from '@ahooksjs/use-url-state';
import vsf, { definePage } from '@vs/vsf-boot';
import { NavBar } from '@vs/vsf-mobile';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';

import Slice45 from '@/assets/Slice45.png';
import Slice46 from '@/assets/Slice46.png';
import Slice47 from '@/assets/Slice47.png';
import Slice48 from '@/assets/Slice48.png';
import DomTitle from '@/components/dom_title';
import Empty from '@/components/Empty';
import PatientHeader from '@/components/patient-header';
const pngList = [
  {
    taskCategory: 4,
    name: '健康日记',
    defPng: Slice45,
    data: [],
  },
  {
    taskCategory: '2-3',
    name: '治疗任务',
    defPng: Slice48,
    data: [],
  },
  {
    taskCategory: 5,
    name: '随访问卷',
    defPng: Slice46,
    data: [],
  },
  {
    taskCategory: 6,
    name: '评估量表',
    defPng: Slice47,
    data: [],
  },
];
const Index: React.FC<any> = (props) => {
  const { planId, patientId } = props.routes.query;
  const [taskDaily, setTaskDaily] = useState<any>();
  const [urlstate, setUrlState] = useUrlState(
    {
      planId,
      activeCard: undefined,
    },
    { navigateMode: 'replace' },
  );
  // 查询个案详情
  const getTasksForDoctor = useCallback(async () => {
    const res =
      await vsf.services?.PlanDoctorController_getTasksForDoctor_4e3be7({
        planId: urlstate?.planId,
      });
    if (res.code === 200) {
      const data = pngList.map((item) => {
        const list = res.data.filter((f) => {
          if (item.taskCategory === '2-3') {
            return (
              f.taskMaster?.taskCategory == 2 || f.taskMaster?.taskCategory == 3
            );
          }
          return f.taskMaster?.taskCategory == item.taskCategory;
        });
        return {
          ...item,
          data: list,
          executeNum: list?.filter((item) => item.isExecute)?.length,
        };
      });
      setTaskDaily({
        data,
        executeNum: res.data?.filter(
          (item) => !item.isExecute && item.taskMaster?.taskCategory != 1,
        ).length,
        num: res.data?.filter((item) => item.taskMaster?.taskCategory != 1)
          .length,
      });
      if (!urlstate?.activeCard) {
        setUrlState({
          activeCard: data?.find((item) => item.data?.length)?.taskCategory,
        });
      }
    }
  }, [urlstate?.planId, setUrlState, urlstate?.activeCard]);
  useEffect(() => {
    getTasksForDoctor();
  }, [getTasksForDoctor]);
  const renderList = () => {
    const list = taskDaily?.data?.find(
      (item) => item.taskCategory == urlstate?.activeCard,
    );
    if (!list?.data?.length) {
      return null;
    }
    return (
      <div className="item_info">
        {list.data?.map((item) => (
          <div
            className={classNames('quest_item', {
              quest_item_active: item.isExecute,
            })}
            key={item.id + item.planDate}
            onClick={() => {
              vsf?.navigateTo(
                `/taskDetail?id=${item?.id}&detailId=${item?.taskMaster?.usedDetail?.id}&isExecute=${item?.isExecute}&planDate=${JSON.stringify(item?.planDate) ?? ''}&taskMasterId=${item?.taskMaster?.id}&planTaskExecute=${item?.planTaskExecute?.[0]?.id}&taskCategory=${item?.taskMaster?.taskCategory}`,
              );
            }}
          >
            <div className="title_box">
              <div className="text ellipsis1">{item.title}</div>
              <div className="btn">{item.isExecute ? '已完成' : '未完成'}</div>
            </div>
            <div className="time_box">
              <div className="plan_time">
                计划时间：
                {dayjs(item.planDate).format('YYYY/MM/DD')}
              </div>

              <div className="success_time">
                完成时间：
                {item.isExecute
                  ? dayjs(item.planTaskExecute?.[0]?.finishDateTime).format(
                      'YYYY/MM/DD',
                    )
                  : '-'}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  return (
    <>
      <NavBar children="专病任务" backArrow={false} />
      <DomTitle title="专病任务" />
      <div className="guide_container">
        <PatientHeader
          patientId={patientId}
          planId={urlstate?.planId}
          onChange={(val) => setUrlState({ planId: val })}
        />
        <div className="content">
          {taskDaily?.data?.length &&
          taskDaily?.data?.find((item) => item.data.length) ? (
            <div className="d_card">
              <div className="title">任务列表</div>
              <div className="d_card_list">
                {taskDaily?.data?.map((item) => {
                  if (!item.data.length) return null;
                  return (
                    <div
                      className={classNames('d_card_item', {
                        active: item.taskCategory == urlstate?.activeCard,
                      })}
                      key={item.taskCategory}
                      onClick={() =>
                        setUrlState({ activeCard: item.taskCategory })
                      }
                    >
                      <img className="card_img" src={item.defPng} alt="" />
                      <div className="d_name">{item.name}</div>
                      <div className="d_num">
                        {item.executeNum ?? '-'}/{item.data?.length ?? '-'}
                      </div>
                    </div>
                  );
                })}
              </div>
              {renderList()}
            </div>
          ) : null}
          {!taskDaily?.data?.length ||
          !taskDaily?.data?.find((item) => item.data.length) ? (
            <div className="empty_content">
              <Empty type="health_care" text="暂无任务" />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default definePage(Index);
