import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, NavBar, Swiper } from '@vs/vsf-mobile';
import { LeftOutline, RightOutline } from 'antd-mobile-icons';
import React, { useEffect, useRef, useState } from 'react';

import low from '@/assets/low.png';
import tall from '@/assets/tall.png';
import Dom_title from '@/components/dom_title';

const Report: React.FC<any> = (props) => {
  const { id, reportList, planPatientId } = props.routes.query;

  const [data, setData] = useState<
    TestReportItemDto3A5E66 & { reportNo: string }
  >();
  const [currentReporId, setCurrentReporId] = useState(id);

  const ref = useRef<any>(null);

  useEffect(() => {
    if (id) {
      handleReortDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (currentReporId) {
      handleReortDetail(currentReporId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReporId]);

  const handleClick = (type) => {
    if (type == 'left') {
      ref.current?.swipePrev();
    } else {
      ref.current?.swipeNext();
    }
  };

  const handleReortDetail = async (id) => {
    const res = await vsf?.services?.PlanController_getByReportDetail_a0935f({
      patientId: planPatientId,
      reportId: id,
    });
    if (res?.code == 200) {
      setData(res?.data);
    }
  };

  const renderReportList = (reportList) => {
    try {
      return JSON?.parse(reportList);
    } catch (error) {
      console.log(error);
    }
  };

  const findItemIndex = (array, value) => {
    return array?.indexOf(Number(value));
  };

  const renderImg = (value) => {
    return (
      value != 'Z' && (
        <img src={value == 'H' ? tall : low} className="tallImg"></img>
      )
    );
  };
  console.log('-scs=c=s=cs==s=c', renderReportList(reportList));

  return (
    <>
      <NavBar children="检验报告" backArrow />
      <Dom_title title="检验报告" />
      <div className="Report-box">
        <div className="Report-header">
          <div
            className="left"
            onClick={() => {
              handleClick('left');
            }}
          >
            <LeftOutline />
            上一份
          </div>
          <div className="center">左右滑动查看其他报告</div>
          <div
            className="rigth"
            onClick={() => {
              handleClick('rigth');
            }}
          >
            下一份
            <RightOutline />
          </div>
        </div>
        <Swiper
          ref={ref}
          defaultIndex={findItemIndex(renderReportList(reportList), id)}
          loop
          onIndexChange={(index) => {
            setCurrentReporId(renderReportList(reportList)?.[index]);
          }}
        >
          {renderReportList(reportList)?.map((v, i) => (
            <Swiper.Item key={i}>
              <div className="first">
                <div className="first-item">
                  <div className="title">患者姓名</div>
                  <div className="text">{data?.patName}</div>
                </div>
                <div className="first-item">
                  <div className="title">报告名称</div>
                  <div className="text">{data?.reportName}</div>
                </div>
                <div className="first-item">
                  <div className="title">报告单号</div>
                  <div className="text">{data?.reportNo}</div>
                </div>
                <div className="first-item">
                  <div className="title">执行时间</div>
                  <div className="text">{data?.executDate}</div>
                </div>
                <div className="first-item">
                  <div className="title">报告时间</div>
                  <div className="text">{data?.reportDate}</div>
                </div>
                <div className="first-item">
                  <div className="title">申请医生</div>
                  <div className="text">{data?.applyDrName}</div>
                </div>
                <div className="first-item">
                  <div className="title">报告医生</div>
                  <div className="text">{data?.reportDrName}</div>
                </div>
              </div>
              {data?.reportDetailList?.length ? (
                <div className="second">
                  <div className="second-header">
                    <div className="item1">检验项目</div>
                    <div className="item2">结果</div>
                    <div className="item3">参考范围</div>
                    <div className="item4">单位</div>
                  </div>
                  <div className="second-content">
                    {data?.reportDetailList?.map((v, i) => {
                      return (
                        <div className="second-item" key={'second' + i}>
                          <div className="item1">{v?.testItemName}</div>
                          <div className="item2">
                            {v?.testResultValue}
                            {renderImg(v?.resultStatus)}
                          </div>
                          <div className="item3">{v?.referenceRanges}</div>
                          <div className="item4">{v?.testResultValueUnit}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ''
              )}
            </Swiper.Item>
          ))}
        </Swiper>

        <div className="prompt">
          <div>温馨提示： </div>
          <div>本结果来源于医院，仅供参考，具体请以医生纸质检查单为准。</div>
        </div>
      </div>
    </>
  );
};

export default definePage(Report);
