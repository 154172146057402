import { createServices } from '@vs/vsf-boot';
export default createServices({
  SpecialistGuidanceController_createSpecialistGuidance_990ad4: {
    method: 'post',
    url: '/api/rag-temp/create-specialist-guidance',
    parameterFomatter: (data?: {
      guidanceBto: CreateSpecialistGuidanceBto092525;
      guidanceDetailBtoList: CreateGuidanceDetailBto092525[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  SpecialistGuidanceController_getListSpecialistGuidanceVoByPlanMasterId_f198c8:
    {
      method: 'post',
      url: '/api/rag-temp/get-list-specialist-guidance-vo-by-plan-master-id',
      parameterFomatter: (data?: { planMasterId: number }) => data,
      responseFormatter: (_, __, data?: SpecialistGuidanceVo765641[]) => data,
    },
});
