import vp2048714 from './vp2048714';
import vp2048715 from './vp2048715';
import vp2048801 from './vp2048801';
import vp2049402 from './vp2049402';
import vp2049403 from './vp2049403';
import vp2049804 from './vp2049804';
import vp2050301 from './vp2050301';
import vp2050601 from './vp2050601';
import vp2050701 from './vp2050701';
import vp2050702 from './vp2050702';
import vp2050703 from './vp2050703';
import vp2051701 from './vp2051701';
import vp2051702 from './vp2051702';
import vp2051703 from './vp2051703';
import vp2051704 from './vp2051704';
import vp2052901 from './vp2052901';
import vp2053305 from './vp2053305';
import vp2053401 from './vp2053401';
import vp2056501 from './vp2056501';
import vp2057501 from './vp2057501';
import vp2060501 from './vp2060501';
import vp2061101 from './vp2061101';
import vp2061201 from './vp2061201';
import vp2061301 from './vp2061301';
import vp2065401 from './vp2065401';
import vp2065702 from './vp2065702';
import vp2065801 from './vp2065801';
import vp2065802 from './vp2065802';
import vp2065803 from './vp2065803';
import vp2065804 from './vp2065804';
import vp2065805 from './vp2065805';
import vp2065806 from './vp2065806';
import vp2065901 from './vp2065901';

export default {
  ...vp2048714,
  ...vp2048715,
  ...vp2048801,
  ...vp2049402,
  ...vp2049403,
  ...vp2049804,
  ...vp2050301,
  ...vp2050601,
  ...vp2050701,
  ...vp2050702,
  ...vp2050703,
  ...vp2051701,
  ...vp2051702,
  ...vp2051703,
  ...vp2051704,
  ...vp2052901,
  ...vp2053305,
  ...vp2053401,
  ...vp2056501,
  ...vp2057501,
  ...vp2060501,
  ...vp2061101,
  ...vp2061201,
  ...vp2061301,
  ...vp2065401,
  ...vp2065702,
  ...vp2065801,
  ...vp2065802,
  ...vp2065803,
  ...vp2065804,
  ...vp2065805,
  ...vp2065806,
  ...vp2065901,
};
