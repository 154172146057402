import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  DatePicker,
  Form,
  Input,
  NavBar,
  Picker,
  Popup,
  Space,
  Tabs,
  TextArea,
} from '@vs/vsf-mobile';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';

import doctorImg from '@/assets/doctor.png';
import down from '@/assets/down.png';
import empty from '@/assets/empty.png';
import Dom_title from '@/components/dom_title';
import {
  ApplyStatusEnum1,
  ApplyStatusTYPE,
  GenderEnum,
  TimePointEnum,
  TITLEEnum,
} from '@/config';
import { getWeek } from '@/utils/index';

const MAXENDTIME = 19;
const validatePhoneNumber = (value) => {
  const phoneRegex = /^1[3-9]\d{9}$/; // 适用于中国大陆的手机号格式
  if (!value) {
    return false;
  } else if (!phoneRegex.test(value)) {
    return false;
  }
  return true;
};

// const dataArr = [
//   {
//     visitApplyVo: {
//       timePoint: 'AM',
//       visitDate: '2024-07-03 00:00:00',
//       thirdDeptId: '206',
//       thirdRegCode: '206-5-1242',
//       thirdRegName: '耳鼻咽喉头颈-副主任-徐斌',
//       thirdRegShiftKey: '206-5-1242_2024-07-03_1_2a',
//       reason: null,
//     },
//     medicalCareManagerVo: {
//       id: 9050,
//       doctorName: '徐斌',
//     },
//     hospitalId: '01',
//     hospitalName: '湖滨院区',
//     applyAmount: null,
//   },
//   {
//     visitApplyVo: {
//       timePoint: 'AM',
//       visitDate: '2024-05-30 00:00:00',
//       thirdDeptId: '8206',
//       thirdRegCode: '8206-5-1242',
//       thirdRegName: '耳鼻咽喉头颈-副主任-徐斌',
//       thirdRegShiftKey: '8206-5-1242_2024-05-30_1_4a',
//       reason: null,
//     },
//     medicalCareManagerVo: {
//       id: 9050,
//       doctorName: '徐斌',
//     },
//     hospitalId: '02',
//     hospitalName: '滨江院区',
//     applyAmount: 2,
//   },
//   {
//     visitApplyVo: {
//       timePoint: 'PM',
//       visitDate: '2024-05-30 00:00:00',
//       thirdDeptId: '82068',
//       thirdRegCode: '82068-5-1242',
//       thirdRegName: '耳畸形门诊-副主任-徐斌',
//       thirdRegShiftKey: '82068-5-1242_2024-05-30_1_4b',
//       reason: null,
//     },
//     medicalCareManagerVo: {
//       id: 9050,
//       doctorName: '徐斌',
//     },
//     hospitalId: '02',
//     hospitalName: '滨江院区',
//     applyAmount: 5,
//   },
//   {
//     visitApplyVo: {
//       timePoint: 'PM',
//       visitDate: '2024-06-02 00:00:00',
//       thirdDeptId: '8206',
//       thirdRegCode: '8206-5-1242',
//       thirdRegName: '耳鼻咽喉头颈-副主任-徐斌',
//       thirdRegShiftKey: '8206-5-1242_2024-06-02_1_0b',
//       reason: null,
//     },
//     medicalCareManagerVo: {
//       id: 9050,
//       doctorName: '徐斌',
//     },
//     hospitalId: '02',
//     hospitalName: '滨江院区',
//     applyAmount: 1,
//   },
// ];
const getRegList = (data) => {
  const groupByhospitalId = data
    .sort((a, b) => {
      return (
        dayjs(a.visitApplyVo?.visitDate).valueOf() -
        dayjs(b.visitApplyVo?.visitDate).valueOf()
      );
    })
    .reduce((group, product) => {
      const { visitApplyVo } = product;
      const { thirdRegName, visitDate, timePoint, thirdRegCode } = visitApplyVo;
      group[thirdRegCode + thirdRegName] =
        group[thirdRegCode + thirdRegName] ?? [];
      const index = group[thirdRegCode + thirdRegName].findIndex((item) => {
        return (
          item?.visitApplyVo?.visitDate &&
          visitDate &&
          item?.visitApplyVo?.visitDate === visitDate
        );
      });
      if (index !== -1) {
        const obj = { ...group[thirdRegCode + thirdRegName]?.[index] };
        group[thirdRegCode + thirdRegName][index] = {
          ...obj,
          reg: obj.reg
            ? { ...obj.reg, [timePoint]: product }
            : { [timePoint]: product },
        };
      } else {
        group[thirdRegCode + thirdRegName].push({
          ...product,
          reg: product.reg
            ? { ...product.reg, [timePoint]: product }
            : { [timePoint]: product },
        });
      }
      return group;
    }, {});
  const arr = Object.keys(groupByhospitalId).map(
    (item) => groupByhospitalId[item],
  );
  console.log('arr=================', arr);
  return arr;
};
const FaceToFaceConsultationRequestForm: any = (props) => {
  const { orderId, order_item_id, unuseCount } = props.routes?.query || {};

  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [activeKey, setActiveKey] = useState(
    unuseCount == 1 ? 'Interview' : 'record',
  );
  const [teamDoctorList, setTeamDoctorList] = useState<any>([]);
  const [doctor, setDoctor] = useState<any>();
  const [isSubmit, setIsSubmit] = useState<any>(false);
  const [data, setData] = useState<OrderRecordTeamPatientVo03Ec0D[] | any>([]);
  const [doctorData, setDoctorData] = useState<
    VisitApplyDoctorVoF6A530[] | any
  >([]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickBack = () => {
    vsf?.goBack();
  };
  const handleTabsChange = (value) => {
    setActiveKey(value);
  };
  const getApplyInfo = async () => {
    const res = await vsf.services.VisitApplyController_getApplyInfo_b2daa1({
      orderServeItemId: Number(order_item_id),
    });
    if (res.code === 200) {
      vsf.refs.face1122.setFieldsValue({
        name: res.data?.visitPatient?.patientName ?? '-',
        phone: res.data?.phone,
      });
      // setOrderData(res.data ?? {});
    }
  };

  const getTeamDoctorList = async () => {
    const res =
      await vsf.services?.OrderRecordController_getVisitApplyDoctorList_46c052?.(
        {
          orderItemId: Number(order_item_id),
        },
      );
    if (res.code === 200 && res?.data) {
      setTeamDoctorList(res?.data);
    }
  };
  const saveApply = async () => {
    const value = vsf.refs?.face1122.getFieldsValue();
    if (
      !value.doctorId ||
      !value.name ||
      !value.phone ||
      !value.visitDate ||
      !value.reason
    ) {
      return vsf.showToast('必填信息不能为空！', 'error');
    }
    if (!validatePhoneNumber(value.phone)) {
      return vsf.showToast('请输入有效的手机号码', 'error');
    }
    setLoading(true);

    const res = await vsf.services?.VisitApplyController_saveApply_fdeaf9({
      bto: {
        orderServeItemId: Number(order_item_id),
        doctorId: value.doctorId?.[0],
        visitDate: value.visitDate
          ? dayjs(value.visitDate?.doc?.visitApplyVo?.visitDate).format(
              'YYYY-MM-DD hh:mm:ss',
            )
          : null,
        timePoint: value.visitDate?.timePoint || null,
        phone: value.phone,
        thirdDeptId: value?.visitDate?.doc?.visitApplyVo?.thirdDeptId,
        thirdRegCode: value?.visitDate?.doc?.visitApplyVo?.thirdRegCode,
        thirdRegName: value?.visitDate?.doc?.visitApplyVo?.thirdRegName,
        thirdRegShiftKey: value?.visitDate?.doc?.visitApplyVo?.thirdRegShiftKey,
        hospitalName: value.visitDate?.doc?.hospitalName,
        hospitalId: value.visitDate?.doc?.hospitalId,
        reason: value.reason,
      },
    });
    setLoading(false);
    if (res.code === 200) {
      vsf.showToast('提交成功!', 'success');
      setIsSubmit(true);
      setActiveKey('record');
    }
  };

  const getVisitApplyByOrderServeItemId = async () => {
    const res =
      await vsf.services?.VisitApplyController_getVisitApplyByOrderServeItemId_93cf3c?.(
        {
          orderServeItemId: Number(order_item_id),
        },
      );
    if (res.code === 200 && res?.data) {
      setData(res?.data);
    }
  };
  const getRegByDoctorId = async (doctorId) => {
    const res = await vsf.services?.RegController_getRegByDoctorId_164091?.({
      doctorId,
      orderId,
    });
    if (res.code === 200 && res?.data) {
      setDoctorData(res?.data);
    }
  };

  const updateStatusVisitApply = async (id) => {
    const res =
      await vsf.services?.VisitApplyController_updateStatusVisitApply_4af036?.({
        btoParam: {
          id,
          applyStatus: 'PATIENT_CANCEL',
        },
        extApiParam: {},
      });
    if (res.code === 200 && res?.data) {
      vsf.showToast('取消成功!', 'success');
      getVisitApplyByOrderServeItemId();
    }
  };

  useEffect(() => {
    if (activeKey === 'Interview' && !isSubmit) {
      getApplyInfo();
      getTeamDoctorList();
    } else {
      getVisitApplyByOrderServeItemId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey]);

  const renderEmpty = () => {
    return (
      <div className="empty">
        <img src={empty} className="img"></img>
        <div className="empty-text">暂无数据</div>
      </div>
    );
  };

  const CheckTime = (props) => {
    const { value, onChange } = props;
    const getTag = (data, applyAmount) => {
      const isLimitBundle = (applyAmount ?? 0) >= 5;
      const date = dayjs();
      let isDisable = false;
      let isCheck = false;
      let isActive = false;
      if (
        data.isStop ||
        (date.hour() >= MAXENDTIME &&
          date.add(1, 'day').format('YYYY-MM-DD') ===
            dayjs(data?.visitApplyVo?.visitDate).format('YYYY-MM-DD'))
      ) {
        isDisable = true;
      } else if (
        value?.doc?.visitApplyVo?.visitDate === data?.visitApplyVo?.visitDate &&
        data?.visitApplyVo?.thirdRegShiftKey ===
          value?.doc.visitApplyVo?.thirdRegShiftKey
      ) {
        isCheck = true;
      } else {
        isActive = true;
      }
      return (
        <div
          className={classNames([
            'btn',
            { disable_btn: isDisable || isLimitBundle },
            { check_btn: isCheck },
            { active_btn: isActive },
          ])}
          onClick={() =>
            !isDisable &&
            !isLimitBundle &&
            onChange({
              doc: data,
              timePoint: data.visitApplyVo?.timePoint,
            })
          }
        >
          {data.isStop ? '停诊' : isLimitBundle ? '已满' : '申请'}
        </div>
      );
    };
    return (
      <div>
        {value?.doc ? (
          dayjs(value?.doc.visitApplyVo?.visitDate).format('YYYY-MM-DD') +
          TimePointEnum[value.timePoint]
        ) : (
          <div className="placeholder">请选择</div>
        )}
        {doctor ? (
          <Popup
            visible={visible1}
            className="my_picker"
            onMaskClick={() => {
              setVisible1(false);
            }}
            bodyStyle={{
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              minHeight: '40vh',
            }}
          >
            <div className="check_time">
              <div className="header">
                <div
                  className="left"
                  onClick={() => {
                    setVisible1(false);
                  }}
                >
                  <img src={down} alt="" />
                </div>
                <div className="name">申请复诊时间</div>
                <div className="right"></div>
              </div>
              <div className="content">
                <div className="doctor_card">
                  <div className="avatar">
                    <img src={doctor?.avatarImg || doctorImg} alt="" />
                  </div>
                  <div className="info">
                    <div className="name_box">
                      <span className="name">{doctor?.doctorName}</span>
                      <span className="posts">{TITLEEnum[doctor?.title]}</span>
                    </div>
                    <div className="profession">{doctor?.departmentName}</div>
                  </div>
                </div>
                <div className="upgrade_line">
                  <div className="line"></div>
                  <div className="center">
                    <span className="text">请选择期望申请的复诊时间</span>
                  </div>
                  <div className="line"></div>
                </div>
                {doctorData.length
                  ? getRegList(doctorData).map((item, index) => {
                      return (
                        <div className="time_card" key={index}>
                          <div className="title">
                            {item[0]?.visitApplyVo?.thirdRegName +
                              '(' +
                              item[0]?.hospitalName +
                              ')'}
                          </div>
                          <div className="time_list">
                            <div className="time_head">
                              <div></div>
                              <div>上午</div>
                              <div>下午</div>
                            </div>
                            {item.map((doc) => {
                              return (
                                <div
                                  key={doc.visitApplyVo?.visitDate}
                                  className="time_item"
                                >
                                  <div className="time">
                                    <div className="week">
                                      {/* {getWeek(dayjs())} */}
                                      {getWeek(doc.visitApplyVo?.visitDate)}
                                    </div>
                                    <div className="day">
                                      {dayjs(
                                        doc.visitApplyVo?.visitDate,
                                      ).format('YYYY-MM-DD') || '-'}
                                    </div>
                                  </div>
                                  <div className="center">
                                    {doc?.reg?.AM
                                      ? getTag(doc?.reg?.AM, doc?.applyAmount)
                                      : null}
                                  </div>
                                  <div className="right">
                                    {doc?.reg?.PM
                                      ? getTag(doc?.reg?.PM, doc?.applyAmount)
                                      : null}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })
                  : renderEmpty()}
              </div>
            </div>
          </Popup>
        ) : null}
      </div>
    );
  };

  const tabList = [
    {
      title: '复诊申请',
      value: 'Interview',
      children: (
        <div className="tab_content1">
          <div className="bg"></div>
          <div className="line"></div>
          <div className="card1">
            <div className="icon"></div>
            <div className="top_bg"></div>
            <Form
              id="face1122"
              className="form"
              layout="horizontal"
              footer={false}
              readOnly={isSubmit || unuseCount == 0}
              onFieldsChange={(data) => {
                if (
                  data?.[0]?.name?.[0] === 'doctorId' &&
                  data?.[0]?.value?.[0] !== doctor?.id
                ) {
                  const doctor =
                    teamDoctorList.find(
                      (d) => d.doctor?.id === data?.[0]?.value?.[0],
                    )?.doctor || null;
                  getRegByDoctorId(doctor?.id);
                  setDoctor(doctor);
                  vsf.refs.face1122.setFieldsValue({
                    hospitalName: null,
                    thirdRegName: null,
                    visitDate: null,
                  });
                }
                if (data?.[0]?.name?.[0] === 'visitDate' && data?.[0]?.value) {
                  // setFaceTime(data?.[0]?.value);
                  vsf.refs.face1122.setFieldsValue({
                    hospitalName: data?.[0]?.value.doc?.hospitalName,
                    thirdRegName:
                      data?.[0]?.value.doc?.visitApplyVo?.thirdRegName,
                  });
                }
              }}
            >
              <Form.Item
                name="name"
                label="复诊患者："
                rules={[{ required: true, message: '姓名不能为空' }]}
                readOnly={true}
              >
                <Input placeholder="请输入姓名" />
              </Form.Item>
              <Form.Item
                name="phone"
                label="联系方式："
                rules={[
                  {
                    required: true,
                    // validator: validatePhoneNumber,
                  },
                ]}
              >
                <Input onChange={console.log} placeholder="请输入联系方式" />
              </Form.Item>
              {/* eslint-disable */}
              <Form.Item
                name="doctorId"
                label="复诊医生："
                trigger="onConfirm"
                onClick={(e, datePickerRef) => {
                  if (unuseCount == 0) return
                  !isSubmit && setVisible(true);
                }}
                required={true}
              >
                <Picker
                  visible={visible}
                  onClose={() => setVisible(false)}
                  columns={[
                    teamDoctorList?.map((item) => ({
                      label: item?.doctor?.doctorName,
                      value: item?.doctor?.id,
                    })),
                  ]}
                  onSelect={(val, extend) => {
                    console.log('onSelect', val, extend.items);
                  }}
                  title="复诊医生"
                  className="my_picker"
                  cancelText={<span className="cancelText">取消</span>}
                  confirmText={<span className="confirmText">确定</span>}
                >
                  {(items, { open }) => {
                    return (
                      <Space align="center">
                        <div onClick={open}>
                          {items.every((item) => item === null)
                            ? <div className='placeholder'>请选择</div>
                            : items
                              .map((item) => item?.label ?? '未选择')
                              .join(' - ')}
                        </div>
                      </Space>
                    );
                  }}
                </Picker>
              </Form.Item>
              <Form.Item
                name="visitDate"
                label="就诊时间："
                onClick={(e, datePickerRef) => {
                  if (unuseCount == 0) return
                  if (!doctor) return vsf.showToast('请选择就诊医生');
                  !isSubmit && setVisible1(true);
                }}
                required={true}
              >
                <CheckTime />
              </Form.Item>
              <Form.Item
                name="thirdRegName"
                label="  号  别："
                readOnly
                hidden={!(vsf.refs.face1122?.getFieldValue('visitDate'))}
              >
                <Input placeholder="请输入号别" />
              </Form.Item>
              <Form.Item
                name="hospitalName"
                label="  院  区："
                readOnly
                hidden={!(vsf.refs.face1122?.getFieldValue('visitDate'))}
              >
                <Input placeholder="请输入院区" />
              </Form.Item>
              {/* eslint-enable */}
              <Form.Item
                name="reason"
                label="申请原因："
                required={true}
                // rules={[{ required: true, message: '申请原因不能为空' }]}
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  showCount
                  maxLength={30}
                  placeholder="请输入申请原因"
                />
              </Form.Item>
            </Form>
          </div>
          <div className="card2">
            <div className="title">温馨提示</div>
            <div className="content">
              <p>1、请先确认医生的出诊时间，非出诊时间无法加号! </p>
              <p>
                2、请至少提前1天提交申请，医生当天的加号名额有限，如未能加号成功，请您理解。
              </p>
              <p>
                3、若加号成功后无法按时就诊，请务必在就诊日期前联系医生助手取消。
              </p>
            </div>
          </div>
          <Button
            disabled={isSubmit || unuseCount == 0}
            onClick={saveApply}
            className="submit_btn_box"
            loading={loading}
          >
            <div className="submit_btn">提交</div>
          </Button>
        </div>
      ),
    },
    {
      title: '申请记录',
      value: 'record',
      children: (
        <div className="tab_content2">
          <div className="list">
            {data.map((item, index) => (
              <div className="card" key={item.id}>
                <div className="header">
                  <div className="card_title">
                    <div className="card_name">
                      {item.visitPatient?.patientName ?? '-'}
                    </div>
                    <div className="card_gender">
                      {GenderEnum[item.visitPatient?.patientGender] ?? '-'}
                    </div>
                    <div className="card_age">
                      {item.visitPatient?.age
                        ? item.visitPatient?.age + '岁'
                        : null}
                    </div>
                  </div>
                  <span
                    className={`state ${item.applyStatus === 'CANCEL' || item.applyStatus === 'OUT_TIME_CANCEL' ? 'state3' : item.applyStatus === 'PATIENT_CANCEL' ? 'state2' : 'state1'}`}
                  >
                    {ApplyStatusEnum1[item?.applyStatus] ?? '-'}
                  </span>
                </div>
                <div className="card_line"></div>
                <div className="infos">
                  <span className="label">联系电话</span>
                  <span className="value">{item?.phone ?? '-'}</span>
                </div>
                <div className="infos">
                  <span className="label">复诊医生</span>
                  <span className="value">
                    {item.doctor?.doctorName ?? '-'}
                  </span>
                </div>
                <div className="infos">
                  <span className="label">就诊时间</span>
                  <span className="value">
                    {item.visitDate
                      ? dayjs(item.visitDate).format('YYYY-MM-DD') +
                        ' ' +
                        TimePointEnum[item?.timePoint]
                      : '-'}
                  </span>
                </div>
                <div className="infos">
                  <span className="label">号别</span>
                  <span className="value">{item?.thirdRegName ?? '-'}</span>
                </div>
                <div className="infos">
                  <span className="label">院区</span>
                  <span className="value">{item?.hospitalName ?? '-'}</span>
                </div>
                <div className="infos">
                  <span className="label">申请原因</span>
                  <span className="value">{item.reason ?? '-'}</span>
                </div>
                {item.applyStatus === 'CANCEL' ? (
                  <div className="infos cancel">
                    <span className="label">驳回原因</span>
                    <span className="value">{item.cancelReason ?? '-'}</span>
                  </div>
                ) : null}
                {item.applyStatus === 'WAIT_AUDIT' ? (
                  <div className="bottom_cancel">
                    <div
                      className="btn"
                      onClick={() => updateStatusVisitApply(item.id)}
                    >
                      取消
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
          {!data?.length && <div className="empty">暂无数据</div>}
          {!hasMore && !!data?.length && (
            <div className="empty">我是有底线的~</div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <NavBar children="复诊需求申请单" backArrow onBack={handleClickBack} />
      <Dom_title title="复诊需求申请单" />
      <div className="face_to_face_consultation_request_form">
        <Tabs
          className="tabs"
          activeKey={activeKey}
          onChange={handleTabsChange}
        >
          {tabList.map((item) => {
            return (
              <Tabs.Tab title={item.title} key={item.value}>
                {item.children}
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </div>
    </>
  );
};

export default definePage(FaceToFaceConsultationRequestForm);
