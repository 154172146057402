import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { ImageViewer } from '@vs/vsf-mobile';
import { uuidv4 } from 'amis';
import classNames from 'classnames';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import React, { useEffect, useRef, useState } from 'react';

import arrowLeft from '@/assets/arrowLeft.png';
import charts from '@/assets/charts.png';
import RePortTabel from '@/components/report_table';
interface HeaderProps {
  data: any;
  type?: 'noReadReport' | 'abnorma';
  patientId?: number;
  [props: string]: any;
}

const renderImgList = (value) => {
  try {
    return value?.split(',');
  } catch (error) {
    console.log(error);
  }
};
const isNumber = (str) => {
  return !isNaN(str) && !isNaN(parseFloat(str)) && isFinite(str);
};
const Index: React.FC<HeaderProps> = (props) => {
  const { data, type, patientId } = props;
  const { user } = props.stores;
  const { setInitCharts, initCharts } = user;
  const {
    invalidWarn,
    executInvalid,
    projectName,
    elementValue,
    reportResource,
    reportDetail,
    indicatorType,
  } = data;
  const uuid = uuidv4();
  const { warnRuleDescription } = invalidWarn?.[0] ?? {};
  const [currentTab, setCurrentTab] = useState('tab');
  const renderIsShowChart = () => {
    return !executInvalid?.find((v) => isNumber(v?.elementValue));
  };
  const chartRef = useRef<any>();

  useEffect(() => {
    if (currentTab == 'chart' && type === 'abnorma') {
      handleCharts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, executInvalid, type]);

  const handleCharts = () => {
    const tabList = executInvalid
      ?.filter((item) => isNumber(item?.elementValue))
      .sort((a, b) => {
        const flag = dayjs(a?.reportTime)?.isBefore(dayjs(b?.reportTime));
        return flag ? -1 : 1;
      });
    const yearData = tabList?.map((v) => {
      return dayjs(v?.reportTime).year() ?? '';
    });
    const data = tabList?.map((v) => {
      return [
        new Date(dayjs(v?.reportTime)?.format('YYYY-MM-DD') ?? '').getTime(),
        v?.elementValue,
      ];
    });
    let left = 35;
    data?.forEach((element) => {
      if (element?.[1]?.length) {
        const strLen = 20 + element?.[1]?.length * 7;
        if (strLen > left) left = strLen;
      }
    });
    const chartsData = tabList?.map((v) => {
      return {
        coord: [
          new Date(dayjs(v?.reportTime)?.format('YYYY-MM-DD') ?? '').getTime(),
          v?.elementValue,
        ],
        value: v?.elementValue,
        name: dayjs(v?.reportTime)?.format('YYYY-MM-DD'),
        label: {
          show: true,
          position: 'top', // 设置标签位置为顶部
          color: v?.abnormal ? '#EB5E3D' : '#1778FF',
          formatter: (value) => {
            if (!v?.reportResource) {
              return `{a|}\n${value?.value}`;
            } else {
              return value?.value;
            }
          },
          rich: {
            a: {
              color: 'red',
              height: 5,
              width: 5,
              backgroundColor: {
                image: arrowLeft,
              },
              position: 'right',
            },
          },
        },
        itemStyle: {
          color: v?.abnormal ? '#EB5E3D' : '#1778FF',
          // 数据点的形状，可以是 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
          symbol: 'circle',
          // 数据点的大小
          symbolSize: 5,
          borderWidth: 0, // 设置边框宽度为0，使得数据点实心
          borderColor: v?.abnormal ? '#EB5E3D' : '#1778FF', // 设置边框颜色与背景色相同
        },
        color: v?.abnormal ? '#EB5E3D' : '#1778FF',
        // 数据点的形状，可以是 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
        symbol: 'circle',
        // 数据点的大小
        symbolSize: 5,
        borderWidth: 0, // 设置边框宽度为0，使得数据点实心
        borderColor: v?.abnormal ? '#EB5E3D' : '#1778FF', // 设置边框颜色与背景色相同
      };
    });
    let start = 0;
    if (chartsData) {
      start =
        chartsData?.length <= 7
          ? 0
          : ((chartsData?.length - 7) / chartsData?.length) * 100;
    }
    const unit = tabList?.[0]?.normalValueUnit;
    const chartDom = document.getElementById(uuid);
    let myChart;
    if (chartDom) {
      myChart = echarts.init(chartDom);
      chartRef.current = myChart;
    }
    const option = {
      xAxis: {
        type: 'time',
        minInterval: 24 * 3600 * 1000, // 设置最小步长为一天（毫秒）
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          formatter: (value, i) => {
            // const date = new Date(value);
            return '{MM}-{dd}';
          },
          hideOverlap: true,
          rotate: 45, // 旋转45度
          showMinLabel: true,
          showMaxLabel: true,
        },
      },
      grid: {
        left: left,
        bottom: 55,
        top: 35,
        right: 30,
      },
      toolbox: {
        show: false,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          saveAsImage: {},
        },
      },
      dataZoom: [
        {
          type: 'inside',
          start, // 初始设置为最后一点
          end: 100, // 显示到最后一项
          filterMode: 'none',
          z: 2,
        },
      ],
      yAxis: {
        type: 'value',
        name: unit,
        scale: true,
        nameGap: 25, // y轴name与横纵坐标轴线的间距
      },
      series: [
        {
          data: data,
          type: 'line',
          smooth: true,
          symbol: 'circle',
          lineStyle: {
            color: '#1778FF',
          },
          markPoint: {
            data: chartsData,
          },
          markLine: {
            symbol: ['none', 'none'],
            label: {
              show: true,
              formatter: (params) => {
                const date = new Date(params.value).getFullYear();
                return date;
              },
            },
            data: yearData?.map((item) => ({ xAxis: `${item}-01-01` })),
            lineStyle: {
              color: '#ccc',
              type: 'solid',
            },
            silent: true,
            z: 1,
          },
          areaStyle: {
            opacity: 0.1,
            color: '#1778FF',
          },
        },
      ],
    };

    myChart && option && myChart.setOption(option);
    myChart && myChart.resize();
    // 监听点击事件
    myChart &&
      myChart.on('click', function (params) {
        console.log(
          `你点击了 ${params.name}，值为 ${params.value}`,
          tabList?.[params.dataIndex],
        );
        const data = tabList?.[params.dataIndex];
        data?.reportId &&
          vsf?.navigateTo(
            `/report?id=${data?.reportId}&reportList=${JSON?.stringify(tabList?.filter((item) => item?.reportId)?.map((v) => v?.reportId))}&planPatientId=${patientId}`,
          );
      });
    myChart &&
      myChart.on('mousewheel', function (event) {
        if (event.zrEvent.ctrlKey) {
          event.zoom = 0.1;
          myChart.dispatchAction(event);
        }
      });
  };

  return (
    <div className="Indicator-item">
      {warnRuleDescription?.length ||
      type === 'noReadReport' ||
      renderImgList(elementValue)?.length ? (
        <div
          className="rule"
          style={{
            padding: type === 'noReadReport' && !reportResource ? 6 : 12,
          }}
        >
          {warnRuleDescription?.length ? (
            <div className="item-content">
              <div className="title">预警规则</div>
              <div className="text">{warnRuleDescription}</div>
            </div>
          ) : null}
          {type === 'noReadReport' && reportResource ? (
            <>
              <div className="item-content">
                <div className="title">患者姓名</div>
                <div className="text">{data?.patName ?? '-'}</div>
              </div>
              <div className="item-content">
                <div className="title">报告名称</div>
                <div className="text">{data?.reportDrName ?? '-'}</div>
              </div>
              <div className="item-content">
                <div className="title">报告单号</div>
                <div className="text">{data?.reportNo ?? '-'}</div>
              </div>
              <div className="item-content">
                <div className="title">执行时间</div>
                <div className="text">{data?.executeDate ?? '-'}</div>
              </div>
              <div className="item-content">
                <div className="title">报告时间</div>
                <div className="text">{data?.reportDate ?? '-'}</div>
              </div>
              <div className="item-content">
                <div className="title">申请医生</div>
                <div className="text">{data?.applyDrName ?? '-'}</div>
              </div>
              <div className="item-content">
                <div className="title">报告医生</div>
                <div className="text">{data?.reportDrName ?? '-'}</div>
              </div>
              <div className="item-content">
                <div className="title">标本类型</div>
                <div className="text">{data?.sampleType ?? '-'}</div>
              </div>
            </>
          ) : null}
          {!reportResource && renderImgList(elementValue)?.length ? (
            <div>
              <div className="date">上传时间：{data?.createdAt}</div>
              <div className="imgContent">
                {renderImgList(elementValue)?.map((v, i) => (
                  <div className="img-box" key={'imgContent' + i}>
                    <img
                      className="imgContent-item"
                      key={'imgContent' + i}
                      src={v}
                      onClick={(e) => {
                        e.stopPropagation();
                        const handlerMulti = ImageViewer.Multi.show({
                          images: renderImgList(elementValue),
                          defaultIndex: i,
                        });
                      }}
                    ></img>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      {(type === 'abnorma' || reportResource) && (
        <div className={classNames(['record', type])}>
          {type === 'abnorma' && (
            <div className="record-header">
              <div>指标记录</div>
              {!renderIsShowChart() && (
                <div className="rigth">
                  <div
                    className={classNames(`btn`, {
                      activeBtn: currentTab == 'tab',
                    })}
                    onClick={() => setCurrentTab('tab')}
                  >
                    列表
                  </div>
                  <div
                    className={classNames(`btn`, {
                      activeBtn1: currentTab == 'chart',
                    })}
                    onClick={() => setCurrentTab('chart')}
                  >
                    图表
                  </div>
                </div>
              )}
            </div>
          )}
          {currentTab == 'tab' && (
            <RePortTabel
              type={type}
              data={type === 'abnorma' ? executInvalid : reportDetail}
              patientId={patientId}
              indicatorType={indicatorType}
            />
          )}
          {currentTab == 'chart' && (
            <div className="chart">
              <div className="indicatorChart" id={uuid}></div>
              <div className="tag">
                <img src={arrowLeft} alt="" />
                外院数据
              </div>
              <div className="title">{projectName}</div>
              {initCharts && (
                <div className="charts" onClick={() => setInitCharts(false)}>
                  <img src={charts} alt="" />
                  <span>双指拖动放大缩小图形</span>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default definePage(Index);
