import { createServices } from '@vs/vsf-boot';
export default createServices({
  CustomerMessageController_readMessage_719346: {
    method: 'post',
    url: '/api/external-customer/read-message',
    parameterFomatter: (data?: { visitorId: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  CustomerMessageController_getUserCustomerMessage_a25ada: {
    method: 'post',
    url: '/api/external-customer/get-user-customer-message',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: CustomerMessageVoC4E381[]) => data,
  },
  CustomerMessageController_getUserNoReadMessageAmount_bf5131: {
    method: 'post',
    url: '/api/external-customer/get-user-no-read-message-amount',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
