import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Popup } from '@vs/vsf-mobile';
import { CheckCircleFill, CloseCircleOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import change_plan from '@/assets/change_plan.png';
interface PickerPopup {
  patientId: string | number;
  planId?: string;
  onChange?: (value: PlanMasterPathwayVoDe76C9) => void;
}

const PickerPopup: React.FC<PickerPopup> = (props) => {
  const { planId, patientId, onChange } = props;
  const [popupVisible, setPopupVisible] = useState(false);
  const [planList, setPlanList] = useState<PlanMasterPathwayVoDe76C9[]>();
  // 查询个案列表
  const getListPlanMasterPathwayVoByPatientId = useCallback(async () => {
    const res =
      await vsf.services?.PlanController_getListPlanMasterPathwayVoByPatientId_395bad(
        {
          patientId: patientId,
        },
      );
    if (res.code === 200) {
      setPlanList(res.data);
      if (planId && onChange) {
        const obj = res.data?.find((item) => item.id == planId);
        obj && onChange(obj);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  useEffect(() => {
    getListPlanMasterPathwayVoByPatientId();
  }, [getListPlanMasterPathwayVoByPatientId]);

  return (
    <div className="changePlanPopup">
      <div className="user_right " onClick={() => setPopupVisible(true)}>
        <div className="change_btn">
          <img src={change_plan} alt="" />
          <span>切换个案</span>
        </div>
      </div>
      <Popup
        visible={popupVisible}
        onClose={() => {
          setPopupVisible(false);
        }}
        className="changePlanPopup_popup"
      >
        <div className="popup_content">
          <CloseCircleOutline
            className="colos"
            onClick={() => {
              setPopupVisible(false);
            }}
          />
          <div className="popup_header">
            <span>切换个案</span>
          </div>
          <div className="popup_list">
            {planList?.map((item) => (
              <div
                className={classNames('plan', {
                  active_plan: item.id == planId,
                })}
                key={item.id}
                onClick={() => {
                  onChange && onChange(item);
                  setPopupVisible(false);
                }}
              >
                <div className="plan_text ellipsis1">
                  {item.currentPlanPathway?.title}
                </div>
                {item.id == planId ? (
                  <CheckCircleFill className="avtive_icon" />
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default definePage(PickerPopup);
