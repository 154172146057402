import './index.less';

import { DownOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import React, { useCallback, useLayoutEffect, useState } from 'react';

import PickerPopup from '../PickerPopup';

interface HeaderProps {
  headerList?: any;
  currentHeader?: PlanIndicatorMonitoringWithWarnVoDe76C9;
  onClick?: (v: any) => void;
  onChange?: (v: any) => void;
  type?: 'noReadReport' | 'abnorma';
}
const renderStr = (value) => {
  if (value?.length > 10) {
    return `${value?.substring(0, 10)}...`;
  }
  return value;
};

const Index: React.FC<HeaderProps> = (props) => {
  const { headerList = [], currentHeader, onClick, onChange, type } = props;
  const [init, setInit] = useState(false);
  const getElementId = useCallback(
    (v) => {
      if (!v) return null;
      const elementId =
        type === 'abnorma'
          ? v?.projectKey || v?.elementId
          : '' + v?.id + v?.reportResource;
      return elementId;
    },
    [type],
  );
  useLayoutEffect(() => {
    if (!currentHeader || init) return;
    const offsetLeft =
      (document.getElementById('scroll_' + getElementId(currentHeader))
        ?.offsetLeft ?? 22) - 22;
    document.getElementById('scroll-box')?.scrollTo({
      left: offsetLeft,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHeader, getElementId]);
  return (
    <div className="IndicatorDetails-header">
      <div className="left" id="scroll-box">
        {headerList?.map((v, index) => (
          <div
            className={classNames(`header-item`, 'ellipsis1', {
              activeItem: getElementId(currentHeader) == getElementId(v),
              sign: v?.abnormal,
            })}
            key={index}
            id={'scroll_' + getElementId(v)}
            onClick={() => {
              onClick && onClick(v);
              setInit(true);
              setTimeout(() => {
                setInit(false);
              }, 300);
            }}
          >
            {renderStr(v?.projectName)}
          </div>
        ))}
      </div>
      <PickerPopup
        containerClassName="rigth"
        list={headerList?.map((v) => {
          return {
            label: v?.projectName,
            value: getElementId(v),
            id: v?.id,
            ...v,
          };
        })}
        defaultValue={{
          ...currentHeader,
          label: currentHeader?.projectName,
          value: getElementId(currentHeader),
        }}
        onChange={(value) => {
          onChange && onChange(value);
          const offsetLeft =
            (document.getElementById('scroll_' + value.value)?.offsetLeft ??
              22) - 22;
          document.getElementById('scroll-box')?.scrollTo({
            left: offsetLeft,
          });
        }}
        getContainer={null}
      >
        <DownOutline className="icon" />
      </PickerPopup>
    </div>
  );
};
export default Index;
