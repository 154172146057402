// housekeep
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { NavBar } from '@vs/vsf-mobile';
import { FileOutline, RightOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import notice_avatar from '@/assets/notice_avatar.png';
import Dom_title from '@/components/dom_title';
import Empty from '@/components/Empty';
import { aichatKefuIdObj, aichatUrlObj, customerUrlList } from '@/config';
import { getWebEnv, jsonToBase64 } from '@/utils';
const Notice: React.FC = (props: any) => {
  const [data, setData] = useState<any>();
  const { user } = props.stores;
  const { currentUserInfo } = user;

  useEffect(() => {
    getUserCustomerMessage();
  }, []);

  const getUserCustomerMessage = async () => {
    const res =
      await vsf?.services?.CustomerMessageController_getUserCustomerMessage_a25ada(
        {},
      );
    if (res?.code == 200) {
      setData(res?.data ?? []);
    }
  };
  const readMessage = async (visitorId) => {
    const res =
      await vsf?.services?.CustomerMessageController_readMessage_719346({
        visitorId,
      });
  };
  const handleClickMajord = (value) => {
    readMessage(value?.visitorId);
    const data = {};
    const jsonStr = JSON.stringify(data); // 将JSON对象转换为字符串
    window.location.href = `${aichatUrlObj?.[getWebEnv()]}/chatIndex?ent_id=${aichatKefuIdObj?.[getWebEnv()]}&visitor_id=${
      value?.visitorType === 'ORDER'
        ? value?.order?.id
        : currentUserInfo?.userAccount
    }&visitor_name=${JSON?.stringify({ patientName: '访客用户', userAccount: currentUserInfo?.userAccount })}&extra=${jsonToBase64(jsonStr)}`;
  };

  const replaceStr = (str) => {
    const replacedStr = str
      .replace(/img\[.*?\]/g, '[图片]')
      .replace(/mutiFile\[.*?\]/g, '[文件]');
    return replacedStr;
  };
  return (
    <>
      <NavBar children="消息" backArrow />
      <Dom_title title="消息" />
      <div className="notice-container">
        {data?.length ? (
          <div className="notice-card">
            {data?.map((item) => (
              <div
                key={item.id}
                className="notice-card-item"
                onClick={() => handleClickMajord(item)}
              >
                <div className="top">
                  <div className="notice-avatar">
                    <img src={notice_avatar} alt="" />
                  </div>
                  <div className="notice-info">
                    <div className="totice_title">
                      <div
                        className="title"
                        style={{
                          height:
                            item.unreadNum && item.lastMessage ? '100%' : 42,
                        }}
                      >
                        {item.nickname}
                      </div>
                      <div className="date">
                        {item.lastTime
                          ? dayjs(item.lastTime).format('YYYY-MM-DD')
                          : '-'}
                      </div>
                    </div>
                    {item.unreadNum && item.lastMessage ? (
                      <div className="totice_content">
                        <div className="content ellipsis1">
                          {replaceStr(item.lastMessage)}
                        </div>
                        {item?.unreadNum ? (
                          <div className="read_num">{item?.unreadNum}</div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
                {item.visitorType === 'ORDER' ? (
                  <div className="totice_visitor">
                    <div className="patientName ellipsis1">
                      就诊人：{item.patient?.patientName}
                    </div>
                    <div className="visitorName ellipsis1">
                      {item.order?.bundleName}
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : (
          <Empty text="暂无新消息" />
        )}
      </div>
    </>
  );
};

export default definePage(Notice);
