import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { NavBar } from '@vs/vsf-mobile';
import React, { useCallback, useEffect, useState } from 'react';

import Dom_title from '@/components/dom_title';
import ReportTable from '@/components/report_table';

const Report: React.FC<any> = (props) => {
  const { id, planPatientId } = props.routes.query;

  const [data, setData] = useState<
    TestReportItemDto3A5E66 & { reportNo: string; sampleType: string }
  >();
  const handleReortDetail = useCallback(
    async (id) => {
      const res = await vsf?.services?.PlanController_getByReportDetail_a0935f({
        patientId: planPatientId,
        reportId: id,
      });
      if (res?.code == 200) {
        setData(res?.data);
      }
    },
    [planPatientId, setData],
  );
  useEffect(() => {
    if (id) {
      handleReortDetail(id);
    }
  }, [id, handleReortDetail]);

  return (
    <>
      <NavBar children="检验报告" backArrow />
      <Dom_title title="检验报告" />
      <div className="report_detail_box">
        <div className="first">
          <div className="first-item">
            <div className="title">患者姓名</div>
            <div className="text">{data?.patName ?? '-'}</div>
          </div>
          <div className="first-item">
            <div className="title">报告名称</div>
            <div className="text">{data?.reportName ?? '-'}</div>
          </div>
          <div className="first-item">
            <div className="title">报告单号</div>
            <div className="text">{data?.reportNo ?? '-'}</div>
          </div>
          <div className="first-item">
            <div className="title">执行时间</div>
            <div className="text">{data?.executDate ?? '-'}</div>
          </div>
          <div className="first-item">
            <div className="title">报告时间</div>
            <div className="text">{data?.reportDate ?? '-'}</div>
          </div>
          <div className="first-item">
            <div className="title">申请医生</div>
            <div className="text">{data?.applyDrName ?? '-'}</div>
          </div>
          <div className="first-item">
            <div className="title">报告医生</div>
            <div className="text">{data?.reportDrName ?? '-'}</div>
          </div>
          <div className="first-item">
            <div className="title">标本类型</div>
            <div className="text">{data?.sampleType ?? '-'}</div>
          </div>
        </div>
        <ReportTable type="noReadReport" data={data?.reportDetailList ?? []} />
      </div>
    </>
  );
};

export default definePage(Report);
