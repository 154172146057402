import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, Grid, NavBar, Popup } from '@vs/vsf-mobile';
import { UpOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import doctorImg from '@/assets/doctor.png';
import DoubleArrow from '@/assets/DoubleArrow.png';
import Dom_title from '@/components/dom_title';
import Empty from '@/components/Empty';
import { orderServeImg, PERSONTYPE } from '@/config';
import { isNumber } from '@/utils';

import ServiceListPopupButton from '../specialist_serrvice_detail/components/serviceListPopupButton';

interface GuidanceItemType {
  time?: string;
  content?: string;
  avatarImg?: string;
  doctorName?: string;
  personType: string;
}

const GuidanceItem: React.FC<GuidanceItemType> = (props) => {
  const { time, content, avatarImg, doctorName, personType } = props;
  const renderName = () => {
    return `${doctorName}${PERSONTYPE?.[personType]}健康建议`;
  };
  return (
    <div className="GuidanceItem">
      <div className="time">{time}</div>
      <div className="GuidanceItem-content">
        <div className="GuidanceItem-header">
          <img className="header-img" src={avatarImg}></img>
          <div className="header-name">{renderName()}</div>
        </div>
        <div className="GuidanceItem-content-text">{content}</div>
      </div>
    </div>
  );
};

const Guidance: React.FC<any> = (props) => {
  const { routes } = props;
  const [list, setList] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [servicesList, setServicesList] = useState<any>([]);
  useEffect(() => {
    handleGetList();
    handleGetservicesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetList = async () => {
    const res =
      await vsf?.services?.SpecialistGuidanceController_queryBySpecialistGuidanceQto_b27134(
        {
          qto: {
            planMasterIdIs: routes.query?.id,
          },
        },
      );
    if (res?.code == 200) {
      setList(res?.data);
    }
  };

  const handleGetservicesList = async () => {
    const res =
      await vsf?.services?.SpecialistGuidanceController_queryOrderServerItem_54d019(
        {
          planMasterIdIs: routes.query?.id,
        },
      );
    if (res?.code == 200) {
      setServicesList(res?.data);
    }
  };

  return (
    <>
      <NavBar children="专病指导" backArrow={false} />
      <Dom_title title="专病指导" />
      <div className="Guidance-contaniner">
        {list?.map((v, i) => {
          const { content, time, doctor } = v;
          return (
            <GuidanceItem
              key={i}
              content={content}
              time={time}
              doctorName={doctor?.doctorName}
              avatarImg={doctor?.avatarImg ?? doctorImg}
              personType={doctor?.personType}
            ></GuidanceItem>
          );
        })}
        {list?.length == 0 && <Empty text="暂无专病指导" />}
        {servicesList?.length > 0 && (
          <div className="popup-bottom" onClick={() => setVisible(true)}>
            <img className="icon" src={DoubleArrow}></img>
            <div className="text">发起咨询</div>
          </div>
        )}
      </div>
      {servicesList?.length > 0 && (
        <Popup
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
          bodyClassName="seek-container"
        >
          <div className="seek" onClick={() => setVisible(false)}>
            <img className="icon" src={DoubleArrow}></img>
            <div className="text">发起咨询</div>
          </div>
          <div className="seek-content">
            <Grid columns={3} gap={8}>
              {servicesList?.map((item, i) => {
                return (
                  <Grid.Item key={i}>
                    <ServiceListPopupButton
                      className="card_item"
                      order_item_id={item.id}
                      serveType={item.serveType}
                      unuseCount={item.serveCount && item.unuseCount === 0}
                      // onLoad={getPatientOrderServeById}
                      value={item}
                      key={i}
                      // onClickCustomer={handleClickMajord}
                    >
                      <div className="services-item">
                        <img
                          src={orderServeImg?.[item?.serveType]}
                          className="item-img"
                        ></img>
                        <div className="name">{item?.serveName}</div>
                        <div className="total_num">
                          {isNumber(item.serveCount)
                            ? '总共' + item.serveCount + '次'
                            : '不限次数'}
                        </div>
                        {isNumber(item.serveCount) ? (
                          <div className="remainder_num">
                            剩余
                            {item.serveCount === 0 ? 0 : item.unuseCount ?? '-'}
                            次
                          </div>
                        ) : null}
                      </div>
                    </ServiceListPopupButton>
                  </Grid.Item>
                );
              })}
            </Grid>
          </div>
        </Popup>
      )}
    </>
  );
};

export default definePage(Guidance);
