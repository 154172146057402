import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Loading, NavBar, Radio } from '@vs/vsf-mobile';
import React, { useEffect, useState } from 'react';

import Slice12 from '@/assets/Slice12.png';
import Slice13 from '@/assets/Slice13.png';
import Dom_title from '@/components/dom_title';
import {
  cardHide,
  getENVIR,
  getIntegerAndDecimalNum,
  isNumber,
  phoneHide,
} from '@/utils';
const gender = {
  MAN: '男',
  WOMAN: '女',
};

// 微信小程序支付参数
interface appletPayParams {
  thirdOrderNo: string; // 订单号
  orderId: string; // 订单号
  payNum: string; // 金额
  tradeNo?: string; // 流水号
  pageType: string; //
}
const OrderConfirmation = (props) => {
  const [data, setData] = useState<BundleTeamServeVoF1Ba03 | any>({});
  const [patientList, setPatientList] = useState<any[]>([]);
  const [patient, setPatient] = useState<any>();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id, specialistDetailId, experienceFlag } = props.routes?.query || {};

  const handleClickBack = () => {
    vsf?.goBack();
  };
  const toPay = () => {
    if (!checked) {
      return vsf.showToast('请先勾选已阅读《服务购买协议》');
    }
    if (!patient) {
      return vsf.showToast('请选择就诊人');
    }
    createOrder();
  };
  const getBundleTeamServeById = async () => {
    const res =
      await vsf.services.BundleTeamController_getBundleTeamServeById_1269e8({
        id: Number(id),
      });
    if (res.code === 200) {
      setData(res.data ?? {});
    }
  };
  const getUserPatientList = async () => {
    const res =
      await vsf.services.OrderRecordController_getUserPatientList_ff0499({});
    if (res.code === 200) {
      setPatientList(res.data ?? []);
      if (res.data?.length === 1) {
        setPatient(res.data?.[0]?.id);
      }
    }
  };
  const createOrder = async () => {
    setLoading(true);
    const res = await vsf.services.OrderRecordController_createOrder_a974a9({
      bundleTeamId: id,
      visitPatientId: patient,
      specialistDetailId: specialistDetailId ?? null,
    });
    if (res.code === 200) {
      getTradeNo(res.data);
    } else {
      setLoading(false);
    }
  };
  const getTradeNo = async (orderId) => {
    const res = await vsf.services.OrderPayController_getTradeNo_9f9f57({
      orderId,
    });
    setLoading(false);
    if (res.code === 200) {
      // orderNumber
      if (!res.data?.orderId || !res.data?.tradeNo) {
        return vsf.showToast('订单创建错误,请重试!', 'error');
      }
      if (data.servePrice === 0) {
        return vsf.navigateTo(
          `/specialist_serrvice_detail?orderId=${res.data?.orderId}&orderStatus=true`,
        );
      }
      const appletPayData: appletPayParams = {
        thirdOrderNo: res.data?.orderId, // 订单号
        orderId: res.data?.orderId, // 订单号
        payNum: data.servePrice, // 金额
        tradeNo: res.data?.tradeNo, // 流水号
        pageType: 'package', //
      };
      wxPay(appletPayData);
    } else {
      // vsf.showToast('创建订单失败!', 'error');
    }
  };
  const wxPay = (data) => {
    const searchStr = new URLSearchParams(data).toString();
    window.uni?.navigateTo({
      url: `/pages/common/pay-select-way-2/pay-select-way-2?${searchStr}`,
      // url: `/pages/common/pay-select-way-2/pay-select-way-2?${encodeURIComponent(JSON.stringify(data))}`,
    });
  };

  useEffect(() => {
    getBundleTeamServeById();
    getUserPatientList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavBar children="购买服务" backArrow onBack={handleClickBack} />
      <Dom_title title="购买服务" />
      <div className="service_order_confirmation">
        <div className="card card1">
          <div className="header">{data.serveBundle?.bundleName ?? '-'}</div>
          <div className="line"></div>
          {!experienceFlag && (
            <div className="infos">
              <span className="label">医生/团队</span>
              <span className="value">
                {data.bundleTeamType === 'TEAM'
                  ? data.serveTeam?.teamName ?? '-'
                  : data.serveDoctor?.doctorName ?? '-'}
              </span>
            </div>
          )}
          {data.bundleTeamType === 'TEAM' && !experienceFlag ? (
            <div className="infos">
              <span className="label">团队信息</span>
              <span className="value">
                {data.serveTeam?.teamMemberDtoList
                  .map((item) => item.doctor?.doctorName)
                  .join('、')}
              </span>
            </div>
          ) : null}
          <div className="infos">
            <span className="label">服务周期</span>
            <span className="value value1">
              {data.serveBundle?.servePeriod
                ? data.serveBundle?.servePeriod + '天'
                : '-'}
            </span>
          </div>
          <div className="line"></div>

          <div className="price_box">
            <div className="label">服务费用</div>
            <div className="price">
              <span className="unit">￥</span>
              {isNumber(data.servePrice) ? (
                <>
                  <span className="price_integer">
                    {getIntegerAndDecimalNum(data.servePrice).integer}.
                  </span>
                  <span className="price_fractional">
                    {getIntegerAndDecimalNum(data.servePrice).decimal}
                  </span>
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
        <div className="card card4">
          <div className="header">选择就诊人</div>
          <div className="info">
            如需增加就诊人，请先点击页面左上角按钮返回至互联网医院首页，然后点击右下角「个人中心」－「就诊人管理」添加就诊人，完成后即可正常使用
          </div>
          <Radio.Group onChange={setPatient} value={patient}>
            <div className="list">
              {patientList?.map((item) => {
                return (
                  <div key={item.id} className="list_item">
                    <div className="left">
                      <div className="name_box">
                        <div className="name">{item.patientName ?? '-'}</div>
                        <div
                          className={
                            'gender ' +
                            (item.patientGender === 'MAN' ? 'man' : 'woman')
                          }
                        >
                          {gender[item.patientGender || 'MAN']}
                        </div>
                        <div className="age">{item.age}岁</div>
                      </div>
                      <div className="info_box">
                        <img src={Slice13} alt="" />
                        <span className="num">{cardHide(item.idNum)}</span>
                        <img className="img2" src={Slice12} alt="" />
                        <span className="num">
                          {phoneHide(item.patientPhone || item.phone || '-')}
                        </span>
                      </div>
                    </div>
                    <div className="right">
                      <Radio value={item.id} />
                    </div>
                  </div>
                );
              })}
            </div>
          </Radio.Group>
        </div>
        {data.serveBundle?.purchaseNotes ? (
          <div className="card card2">
            <div className="header">购买须知</div>
            <div className="content">
              <p className="item_info">
                {data.serveBundle?.purchaseNotes ?? '-'}
              </p>
            </div>
          </div>
        ) : null}
        <div className="card card3">
          <span className="radio" onClick={() => setChecked(!checked)}>
            <Radio
              style={{
                '--icon-size': '14px',
                '--font-size': '14px',
              }}
              checked={checked}
            />
          </span>
          <span className="text">我已阅读并知悉</span>
          <a
            onClick={() =>
              vsf.navigateTo(`/agreement?id=${data.serveBundle?.id}`)
            }
            className="text1"
          >
            《服务购买协议》
          </a>
        </div>
        <Button
          // disabled={!checked || !patient}
          className="pay_btn"
          onClick={toPay}
          loading={loading}
        >
          {experienceFlag ? '去体验' : '去支付'}
        </Button>
      </div>
    </>
  );
};

export default definePage(OrderConfirmation);
