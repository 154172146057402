import './index.less';

import dayjs from 'dayjs';
import React from 'react';

import user_avatar_png from '@/assets/user_avatar.png';
import { GenderEnum } from '@/config';
interface UserInfoProps {
  patient?: any;
  currentPlanPathway?: any;
}

const Index: React.FC<UserInfoProps> = (props) => {
  const { patient = {}, currentPlanPathway = {} } = props;

  return (
    <div className="user_info_card">
      <div className="avatar">
        <img src={user_avatar_png} alt="" />
      </div>
      <div className="user_name_box">
        <div className="user_name">{patient?.patientName ?? '-'}</div>
        <div className="user_gender">
          {patient?.patientGender ? GenderEnum[patient?.patientGender] : '-'}
        </div>
        <div className="user_age">
          {patient.birthday
            ? dayjs().year() - dayjs(patient.birthday).year()
            : '-'}
          岁
        </div>
      </div>
      <div className="user_right">
        <div className="user_right_text ellipsis1">
          {currentPlanPathway?.title ?? '-'}
        </div>
      </div>
    </div>
  );
};
export default Index;
