import './index.less';

import useUrlState from '@ahooksjs/use-url-state';
import vsf, { definePage } from '@vs/vsf-boot';
import { ImageViewer, NavBar, Picker, SearchBar, Steps } from '@vs/vsf-mobile';
import { useThrottleFn } from 'ahooks';
import { DownOutline, RightOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';

import empty from '@/assets/empty1.png';
import triangle from '@/assets/Slice49.png';
import DomTitle from '@/components/dom_title';
import IndicatorBottom from '@/components/indicator-bottom';
import PatientHeader from '@/components/patient-header';
import PickerPopup from '@/components/PickerPopup';
const { Step } = Steps;
interface CasePicker {
  title?: string;
  value?: any;
  visible?: boolean;
  columns?: any;

  onClose?: () => void;
  onConfirm?: (value) => void;
  onClick?: () => void;
}

interface StepItemType {
  isSuspension?: boolean;
  isImg?: boolean;
  value?: any;
  title?: string;
  time?: string;
  onClickReport?: (e) => void;
}

const hanleJosnStr = (value, type: 'stringify' | 'parse') => {
  try {
    JSON?.parse;
    if (type == 'stringify') return JSON?.stringify(value);
    return JSON?.parse(value);
  } catch (error) {
    console.log(error);
  }
};

const renderStr = (value, num) => {
  if (value?.length > num) {
    return `${value?.substring(0, num)}...`;
  }
  return value;
};
const StepItem: React.FC<StepItemType> = (props) => {
  const {
    value,
    isSuspension = false,
    isImg = false,
    title,
    time,
    onClickReport,
  } = props;
  const isAbnormal = () => {
    return value?.reportDetailList?.find(
      (i) => i?.resultStatus == 'H' || i?.resultStatus == 'L',
    );
  };

  const handelClick = () => {
    if (isImg) {
      const handlerMulti = ImageViewer.Multi.show({
        images: value?.value,
        defaultIndex: 0,
      });
    } else {
      onClickReport && onClickReport(value);
    }
  };

  return (
    <div className="report-StepItem" onClick={handelClick}>
      <div className="report-StepItem-header">
        <div className="title ellipsis1">{title}</div>
        {/* 缺少判断字段 */}
        {!isImg && isAbnormal() && <div className="rigth">异常</div>}
      </div>
      <div
        className={classNames(`report-StepItem-content`, {
          imgContent: isImg,
        })}
      >
        {isImg ? (
          <>
            {value?.value?.map((v, i) => (
              <img
                className="imgContent-item"
                key={'imgContent' + i}
                src={v}
                onClick={(e) => {
                  e.stopPropagation();
                  const handlerMulti = ImageViewer.Multi.show({
                    images: value?.value,
                    defaultIndex: i,
                  });
                }}
              ></img>
            ))}
          </>
        ) : (
          <div className="row">
            <div className="time">申请时间：{time}</div>
            <div
              className="text"
              onClick={() => {
                !isImg && onClickReport && onClickReport(value);
              }}
            >
              查看报告 <RightOutline />
            </div>
          </div>
        )}
      </div>
      {isSuspension && <div className="left-absolute">外院</div>}
    </div>
  );
};

const CasePicker: React.FC<CasePicker> = (props) => {
  const {
    title,
    value = [],
    visible,
    columns,
    onClose,
    onConfirm,
    onClick,
  } = props;

  return (
    <>
      <div
        className="CasePicker-contaniner"
        onClick={() => {
          onClick && onClick();
        }}
      >
        <div className="title">{renderStr(value?.label ?? title, 5)}</div>
        <img className="triangle" src={triangle}></img>
      </div>
      <Picker
        columns={[columns]}
        visible={visible}
        onClose={() => {
          onClose && onClose();
        }}
        value={[value?.value]}
        onConfirm={(v) => {
          const selectValie = columns?.find((ietm) => ietm?.value == v?.[0]);
          onConfirm && onConfirm(selectValie);
        }}
      />
    </>
  );
};
const IndicatorDetails: React.FC<any> = (props) => {
  const { planId, patientId, projectName } = props.routes.query;

  const [urlstate, setUrlState] = useUrlState(props.routes?.query, {
    navigateMode: 'replace',
  });
  const [searchStr, setSearchStr] = useState(null);
  const [filterType, setFilterType] = useState<'tab' | 'search'>('tab');
  const [reportList, setReportList] = useState<any>([]);
  const [currentRepor, setCurrentRepor] = useState<any>([
    { value: '全部', label: '全部' },
  ]);

  const handleSearchChange = (value) => {
    setFilterType('search');
    setSearchStr(value);
  };
  const { run } = useThrottleFn(handleSearchChange, { wait: 500 });
  const renderTabList = (value) => {
    const list = value
      ?.map((v) => v?.testReportItemBaseList)
      ?.flat()
      ?.map((v) => v?.testItemCode);
    return Array.from(new Set(list)) as any;
  };
  const mergeAndSortByDate = (array1, array2) => {
    const mergedArray = array1?.concat(array2);
    mergedArray?.sort((a, b) => {
      return dayjs(a.applyDate).isAfter(dayjs(b.applyDate));
    });
    return mergedArray;
  };
  const renderImgList = (value) => {
    try {
      return value?.split(',');
    } catch (error) {
      console.log(error);
    }
  };
  const handleReortList = useCallback(async () => {
    const res = await vsf?.services?.PlanController_queryMyReport_a13f6e({
      patientId,
      planMasterId: urlstate?.planId,
    });
    if (res?.code == 200) {
      const data = res?.data;
      const { planTaskExecuteWithElementDtos, testReportItemDtos } = data;
      const suspensionList = (planTaskExecuteWithElementDtos ?? [])?.map(
        (v) => {
          return {
            ...v,
            isSuspension: true,
            testItemCode: v?.elementId,
            testItemName: v?.elementId,
            value: renderImgList(v?.elementValue),
            testReportItemBaseList: [
              {
                ...v,
                isSuspension: true,
                testItemCode: v?.elementId,
                testItemName: v?.elementId,
                value: renderImgList(v?.elementValue),
              },
            ],
          };
        },
      );
      setReportList(
        mergeAndSortByDate(suspensionList ?? [], testReportItemDtos ?? []),
      );
    }
  }, [patientId, urlstate?.planId]);

  const guidanceType = 'NEW_REPORT';
  const handelOk = async (str: string) => {
    const res =
      await vsf?.services?.SpecialistGuidanceController_createSpecialistGuidance_990ad4(
        {
          guidanceBto: {
            planMasterId: planId,
            content: str,
            guidanceType,
          },
        },
      );
    if (res?.code === 200) {
      vsf.showToast('回复成功', 'success');
      return true;
    }
    return false;
  };

  const handleClickReportChange = (value) => {
    setFilterType('tab');
    const find = currentRepor?.find((v) => v?.value == value?.value);
    if (find) {
      const activeList = currentRepor?.filter((v) => v?.value != value?.value);
      if (activeList.length) {
        setCurrentRepor(activeList);
      } else {
        setCurrentRepor([{ value: '全部', label: '全部' }]);
      }
    } else {
      const all = currentRepor?.find((v) => v?.value == '全部');
      if (all) {
        setCurrentRepor([value]);
      } else {
        setCurrentRepor([...currentRepor, value]);
      }
    }
  };
  const handleClickReport = (value) => {
    vsf?.navigateTo(
      `/report?id=${value?.id}&reportList=${JSON?.stringify(reportList?.map((v) => v?.id))}&planPatientId=${patientId}`,
    );
  };
  useEffect(() => {
    handleReortList();
  }, [handleReortList]);

  const renderEmpty = (text = '暂无指标记录') => {
    return (
      <div className="render-mine-empty">
        <img src={empty} className="render-img"></img>
        <div className="render-empty-text">{text}</div>
      </div>
    );
  };
  const renderReportList = (currentRepor) => {
    if (filterType === 'tab') {
      const allRepor = currentRepor?.find((v) => v?.value == '全部');
      if (allRepor) return reportList;
      return reportList.filter((item) =>
        currentRepor.find(
          (_c) =>
            _c.value === item.testItemCode ||
            item.testReportItemBaseList?.find(
              (_t) => _t.testItemCode === _c.value,
            ),
        ),
      );
    } else {
      if (!searchStr) return reportList;
      return reportList.filter(
        (item) =>
          item?.reportName?.includes(searchStr) ||
          item?.projectName?.includes(searchStr) ||
          item?.testCategName?.includes(searchStr),
      );
    }
  };
  useEffect(() => {
    if (
      reportList?.length &&
      projectName &&
      reportList?.find((item) => projectName?.includes(item?.testItemCode))
    ) {
      const projectNameList = projectName && projectName.split(',');
      const current = projectNameList
        ? projectNameList.map((item) => ({
            value: item,
            label: item,
          }))
        : null;
      setCurrentRepor(current);
    }
  }, [reportList, projectName]);

  return (
    <>
      <NavBar children="患者报告" backArrow={false} />
      <DomTitle title="患者报告" />
      <div className="patient-report-container">
        <div className="top">
          <div className="fixed-top">
            <PatientHeader
              patientId={patientId}
              planId={urlstate?.planId}
              onChange={(val) => setUrlState({ planId: val })}
            />
            <div className="CaseManagement-header">
              <SearchBar
                placeholder="搜索"
                icon={false}
                className="search"
                onChange={run}
              />
            </div>
            <div className="report-header">
              <div className="left">
                {reportList?.length ? (
                  <div
                    className={classNames(`report-item`, 'ellipsis1', {
                      activeReport: currentRepor?.find(
                        (item) => item?.value == '全部',
                      ),
                    })}
                    onClick={() => {
                      setFilterType('tab');
                      setCurrentRepor([{ value: '全部', label: '全部' }]);
                    }}
                  >
                    全部
                  </div>
                ) : null}
                {renderTabList(reportList)?.map((v, i) => (
                  <div
                    className={classNames(`report-item`, 'ellipsis1', {
                      activeReport: currentRepor?.find(
                        (item) => item?.value == v,
                      ),
                    })}
                    key={'report' + i}
                    onClick={() => {
                      handleClickReportChange({ label: v, value: v });
                    }}
                  >
                    {renderStr(v, 10)}
                  </div>
                ))}
              </div>
              <PickerPopup
                containerClassName="rigth"
                isCheckbox
                list={renderTabList(reportList)?.map((v) => {
                  return {
                    label: v,
                    value: v,
                  };
                })}
                defaultValue={currentRepor}
                onChange={(value) => {
                  setFilterType('tab');
                  if (value?.length == 0)
                    return setCurrentRepor([{ value: '全部', label: '全部' }]);
                  const all = value?.filter((v) => v?.value !== '全部');
                  setCurrentRepor(all);
                }}
              >
                <DownOutline className="icon" />
              </PickerPopup>
            </div>
          </div>
        </div>
        <div className="patient-report-step-container">
          {reportList?.length > 0 && (
            <div className="report-content">
              <div className="report-step">
                <Steps direction="vertical">
                  {renderReportList(currentRepor)?.map((v, i) => (
                    <Step
                      title={`${v?.isSuspension ? '上传时间：' + v?.createdAt : '报告时间：'}${v?.reportDate ?? ''}`}
                      status="finish"
                      key={'Step' + i}
                      description={
                        <StepItem
                          isSuspension={v?.isSuspension}
                          isImg={v?.isSuspension}
                          value={v}
                          title={v?.reportName ?? v.projectName}
                          time={v?.applyDate}
                          onClickReport={handleClickReport}
                        />
                      }
                    />
                  ))}
                  {renderReportList(currentRepor)?.length == 0 &&
                    renderEmpty('')}
                </Steps>
              </div>
            </div>
          )}
          {reportList?.length == 0 && renderEmpty('暂无报告')}
        </div>
        {reportList?.length ? (
          <IndicatorBottom showLeft={false} onOk={handelOk} />
        ) : null}
      </div>
    </>
  );
};

export default definePage(IndicatorDetails);
