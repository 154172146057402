import './index.less';

import useUrlState from '@ahooksjs/use-url-state';
import vsf, { definePage } from '@vs/vsf-boot';
import { Box, ImageUploader, NavBar, Swiper } from '@vs/vsf-mobile';
import { DownOutline, RightOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import React, { useEffect, useRef, useState } from 'react';

import arrowLeft from '@/assets/arrowLeft.png';
import charts from '@/assets/charts.png';
import empty from '@/assets/empty1.png';
import DomTitle from '@/components/dom_title';
import { isImgSrc } from '@/utils';

import PickerPopup from '../mine/PickerPopup';
const size = 50;

interface IndicatorItemType {
  value?: any;
  key?: number;
  type?: string;
  onJumpUrl?: (e) => void;
}

const IndicatorItem: React.FC<IndicatorItemType> = (props) => {
  const { value, onJumpUrl, type } = props;
  return (
    <div className="IndicatorItem">
      <div className="item-title">{value?.planPathway?.title}路径</div>
      {value?.warnRuleDescription ? (
        <div className="item-content">
          <div className="title">预警规则</div>
          <div className="text">{value?.warnRuleDescription}</div>
        </div>
      ) : null}
      {value?.warnSuggest ? (
        <div className="item-content">
          <div className="title">处理意见</div>
          <div
            className={classNames(`text`, {
              url: value?.serveBundleId && type !== 'health_care',
            })}
            onClick={() => {
              value?.serveBundleId &&
                type !== 'health_care' &&
                onJumpUrl &&
                onJumpUrl(value);
            }}
          >
            {value?.warnSuggest}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const IndicatorDetails: React.FC<any> = (props) => {
  const { id, planPatientId, type, planId } = props.routes.query;
  const { user } = props.stores;
  const { setInitCharts, initCharts } = user;
  const [headerList, setHeaderList] = useState<any>([{}, {}, {}, {}, {}]);
  const [currentHeader, setCurrentHeader] = useState<any>();
  const { plan } = props.stores;
  const { patientPlanTask } = plan;
  const [urlstate, setUrlState] = useUrlState(
    { ...props.routes?.query, currentTab: 'tab' },
    {
      navigateMode: 'replace',
    },
  );
  const [data1, setData1] = useState<PlanIndicatorMonitoringWithWarnVoDe76C9>();

  const chartRef = useRef<any>();

  useEffect(() => {
    if (patientPlanTask?.length) {
      if (id && !planId) {
        const filterList =
          patientPlanTask
            ?.filter((v) => v?.elementId != id)
            ?.sort((a, b) => b?.abnormal - a?.abnormal) ?? [];
        const findObj = patientPlanTask?.find((v) => v?.elementId == id);
        setHeaderList([findObj, ...filterList]);
        setCurrentHeader(findObj);
      } else {
        const filterList =
          patientPlanTask?.sort((a, b) => b?.abnormal - a?.abnormal) ?? [];
        setHeaderList([...filterList]);
        setCurrentHeader(filterList?.[0]);
      }
    } else {
      getHeaderList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPlanTask, id]);

  useEffect(() => {
    if (headerList?.length && urlstate.currentHeader) {
      const findObj = headerList?.find(
        (v) => v?.elementId == urlstate.currentHeader,
      );
      setCurrentHeader(findObj);
    }
  }, [headerList, urlstate.currentHeader]);

  useEffect(() => {
    console.log(planPatientId, currentHeader?.elementId);
    if (planPatientId && currentHeader?.elementId) getUnExecuteTasks1();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planPatientId, currentHeader?.elementId]);

  useEffect(() => {
    if (urlstate?.currentTab == 'chart') {
      handleCharts();
    } else {
      // chartRef?.current?.dispose();/
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlstate?.currentTab, data1]);

  const handleCharts = () => {
    const tabList = data1?.executInvalid
      ?.filter((item) => isNumber(item?.elementValue))
      .sort((a, b) => {
        const flag = dayjs(a?.reportTime)?.isBefore(dayjs(b?.reportTime));
        return flag ? -1 : 1;
      });
    const yearData = tabList?.map((v) => {
      return dayjs(v?.reportTime).year() ?? '';
    });
    const data = tabList?.map((v) => {
      return [
        new Date(dayjs(v?.reportTime)?.format('YYYY-MM-DD') ?? '').getTime(),
        v?.elementValue,
      ];
    });
    let left = 35;
    data?.forEach((element) => {
      if (element?.[1]) {
        const strLen = 20 + String(element?.[1])?.length * 7;
        if (strLen > left) left = strLen;
      }
    });
    const chartsData = tabList?.map((v) => {
      return {
        coord: [
          new Date(dayjs(v?.reportTime)?.format('YYYY-MM-DD') ?? '').getTime(),
          v?.elementValue,
        ],
        value: v?.elementValue,
        name: dayjs(v?.reportTime)?.format('YYYY-MM-DD'),
        label: {
          show: true,
          position: 'top', // 设置标签位置为顶部
          color: v?.abnormal ? '#EB5E3D' : '#1DBCC0',
          formatter: (value) => {
            if (!v?.reportResource) {
              return `{a|}\n${value?.value}`;
            } else {
              return value?.value;
            }
          },
          rich: {
            a: {
              color: 'red',
              height: 5,
              width: 5,
              backgroundColor: {
                image: arrowLeft,
              },
              position: 'right',
            },
          },
        },
        itemStyle: {
          color: v?.abnormal ? '#EB5E3D' : '#1DBCC0',
          // 数据点的形状，可以是 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
          symbol: 'circle',
          // 数据点的大小
          symbolSize: 5,
          borderWidth: 0, // 设置边框宽度为0，使得数据点实心
          borderColor: v?.abnormal ? '#EB5E3D' : '#1DBCC0', // 设置边框颜色与背景色相同
        },
        color: v?.abnormal ? '#EB5E3D' : '#1DBCC0',
        // 数据点的形状，可以是 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
        symbol: 'circle',
        // 数据点的大小
        symbolSize: 5,
        borderWidth: 0, // 设置边框宽度为0，使得数据点实心
        borderColor: v?.abnormal ? '#EB5E3D' : '#1DBCC0', // 设置边框颜色与背景色相同
      };
    });
    let start = 0;
    if (chartsData) {
      start =
        chartsData?.length <= 7
          ? 0
          : ((chartsData?.length - 7) / chartsData?.length) * 100;
    }
    const unit = tabList?.[0]?.normalValueUnit;
    const chartDom = document.getElementById('indicatorChart');
    let myChart;
    if (chartDom) {
      myChart = echarts.init(chartDom);
      chartRef.current = myChart;
    }
    const option = {
      xAxis: {
        type: 'time',
        minInterval: 24 * 3600 * 1000, // 设置最小步长为一天（毫秒）
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value) => {
            return '{MM}-{dd}';
          },
          hideOverlap: true,
          rotate: 45, // 旋转45度
          showMinLabel: true,
          showMaxLabel: true,
        },
      },
      grid: {
        left,
        bottom: 55,
        top: 40,
        right: 30,
      },
      toolbox: {
        show: false,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          saveAsImage: {},
        },
      },
      dataZoom: [
        {
          type: 'inside',
          start: 30, // 初始设置为最后一点
          end: 100, // 显示到最后一项
          filterMode: 'none',
          z: 2,
        },
      ],
      yAxis: {
        type: 'value',
        name: unit,
        scale: true,
        nameGap: 25, // y轴name与横纵坐标轴线的间距
      },
      series: [
        {
          data: data,
          type: 'line',
          // smooth: true,
          // symbol: 'circle',
          lineStyle: {
            color: '#1DBCC0',
          },
          markPoint: {
            data: chartsData,
          },
          markLine: {
            symbol: ['none', 'none'],
            label: {
              show: true,
              formatter: (params) => {
                const date = new Date(params.value).getFullYear();
                return date;
              },
            },
            data: yearData?.map((item) => ({ xAxis: `${item}-01-01` })),
            lineStyle: {
              color: '#ccc',
              type: 'solid',
            },
            silent: true,
            z: 1,
          },
        },
      ],
    };

    myChart && option && myChart.setOption(option);
    // 监听点击事件
    myChart &&
      myChart.on('click', function (params) {
        console.log(
          `你点击了 ${params.name}，值为 ${params.value}`,
          tabList?.[params.dataIndex],
        );
        const data = tabList?.[params.dataIndex];
        data?.reportId &&
          vsf?.navigateTo(
            `/report?id=${data?.reportId}&reportList=${JSON?.stringify([data?.reportId])}&planPatientId=${planPatientId}`,
          );
      });
    myChart && myChart.resize();
    myChart &&
      myChart.on('mousewheel', function (event) {
        if (event.zrEvent.ctrlKey) {
          event.zoom = 0.1;
          myChart.dispatchAction(event);
        }
      });
  };

  // 查询个案详情--待处理
  const getUnExecuteTasks1 = async () => {
    const data = {
      patientId: planPatientId,
      projectKey: currentHeader?.elementId,
    } as any;
    if (planId) data.planMasterId = planId;
    const res =
      await vsf.services?.RagPlanController_getPatientIndicatorWithWarnInfo_aca4de(
        data,
      );
    if (res.code === 200) {
      setData1(res.data);
    }
  };

  const getHeaderList = async () => {
    const data = {
      patientId: planPatientId,
    } as any;
    if (planId) data.planMasterId = planId;
    const res =
      await vsf.services?.RagPlanController_getPatientPlanTaskExecuteElement_552d18(
        data,
      );
    if (res.code === 200) {
      const patientPlanTask = res.data;
      if (id) {
        const filterList =
          patientPlanTask
            ?.filter((v) => v?.elementId != id)
            ?.sort((a, b) => b?.abnormal - a?.abnormal) ?? [];
        const findObj = patientPlanTask?.find((v) => v?.elementId == id);
        setHeaderList([findObj, ...filterList]);
        setCurrentHeader(findObj);
      } else {
        const filterList =
          patientPlanTask?.sort((a, b) => b?.abnormal - a?.abnormal) ?? [];
        setHeaderList([...filterList]);
        setCurrentHeader(filterList?.[0]);
      }
    }
  };

  const handleJump = (value) => {
    const id = value?.serveBundleId;
    vsf?.navigateTo(`/service_details?id=${id}`);
  };

  const renderStr = (value) => {
    if (value?.length > 10) {
      return `${value?.substring(0, 10)}...`;
    }
    return value;
  };

  const isNumber = (str) => {
    return !isNaN(str) && !isNaN(parseFloat(str)) && isFinite(str);
  };

  const renderIsShowChart = () => {
    return !data1?.executInvalid?.find((v) => isNumber(v?.elementValue));
  };

  const renderEmpty = () => {
    return (
      <div className="render-empty">
        <img src={empty} className="render-img"></img>
        <div className="render-empty-text">暂无数据</div>
      </div>
    );
  };

  console.log('=====', headerList, currentHeader);

  return (
    <>
      <NavBar children="指标记录" backArrow={false} />
      <DomTitle title="指标记录" />
      <div className="IndicatorDetails-container">
        <div className="IndicatorDetails-header">
          <div className="left" id="scroll-box">
            {headerList?.map((v, index) => (
              <div
                className={classNames(`header-item`, {
                  activeItem: currentHeader?.elementId == v?.elementId,
                  sign: v?.abnormal,
                })}
                key={index}
                id={'scroll_' + v.elementId}
                onClick={() => {
                  setCurrentHeader(v);
                  setUrlState({
                    currentHeader: v.elementId,
                    currentTab: 'tab',
                  });
                }}
              >
                {renderStr(v?.projectName)}
              </div>
            ))}
          </div>
          <PickerPopup
            containerClassName="rigth"
            list={headerList?.map((v) => {
              return {
                label: v?.projectName,
                value: v?.elementId,
                id: v?.id,
                ...v,
              };
            })}
            defaultValue={{
              ...currentHeader,
              label: currentHeader?.projectName,
              value: currentHeader?.elementId,
            }}
            onChange={(value) => {
              setCurrentHeader(value);
              const offsetLeft =
                (document.getElementById('scroll_' + value.elementId)
                  ?.offsetLeft ?? 22) - 22;
              document.getElementById('scroll-box')?.scrollTo({
                left: offsetLeft,
              });
              setUrlState({
                currentHeader: value.elementId,
                currentTab: 'tab',
              });
            }}
          >
            {/* <div className="rigth"> */}
            {/* <div className="rigth-left"></div>  */}
            筛选 <DownOutline className="icon" />
            {/* </div> */}
          </PickerPopup>
        </div>
        <div className="IndicatorDetails-content">
          {data1?.invalidWarn &&
          data1?.invalidWarn?.length > 0 &&
          currentHeader?.abnormal &&
          data1?.invalidWarn?.filter(
            (item) => item.warnRuleDescription || item.warnSuggest,
          )?.length > 0 ? (
            <div className="indicator-opinion">
              <div className="indicator-header">
                {currentHeader?.projectName}指标异常处理意见
              </div>
              <Swiper>
                {data1?.invalidWarn
                  ?.filter(
                    (item) => item.warnRuleDescription || item.warnSuggest,
                  )
                  ?.map((v, index) => (
                    <Swiper.Item key={index}>
                      <IndicatorItem
                        value={v}
                        key={index}
                        onJumpUrl={handleJump}
                        type={type}
                      ></IndicatorItem>
                    </Swiper.Item>
                  ))}
              </Swiper>
              {/* {renderIndicatorInfo(data)?.map((v, index) => (
                <IndicatorItem
                  value={v}
                  key={index}
                  onJumpUrl={handleJump}
                  type={type}
                ></IndicatorItem>
              ))} */}
            </div>
          ) : (
            ''
          )}
          {data1?.executInvalid && data1?.executInvalid?.length > 0 ? (
            // {renderIndicatorInfo(data, 'tab')?.length > 0 ? (
            <div className="record">
              <div className="record-header">
                <div>指标记录</div>
                {!renderIsShowChart() && (
                  <div className="rigth">
                    <div
                      className={classNames(`btn`, {
                        activeBtn: urlstate?.currentTab == 'chart',
                      })}
                      onClick={() => setUrlState({ currentTab: 'tab' })}
                    >
                      列表
                    </div>
                    <div
                      className={classNames(`btn`, {
                        activeBtn1: urlstate?.currentTab == 'tab',
                      })}
                      onClick={() => setUrlState({ currentTab: 'chart' })}
                    >
                      图表
                    </div>
                  </div>
                )}
              </div>
              {urlstate?.currentTab == 'tab' && (
                <div className="record-content">
                  <div className="item item-header">
                    {/* <div className="time">测量时间</div>
                   <div className="time">数值</div> */}
                    <div className="time1">
                      {currentHeader?.indicatorType !== 'LATEST'
                        ? '测量日期'
                        : '报告日期'}
                    </div>
                    <div className="time2">结果</div>
                    {currentHeader?.indicatorType !== 'LATEST' ? null : (
                      <div className="time3">参考范围</div>
                    )}
                    <div className="time4">单位</div>
                    {/* <div className="time">测量时间</div>
                   <div className="time">数值</div> */}
                  </div>
                  {data1?.executInvalid?.map((v, i) => {
                    return (
                      <div
                        className="item"
                        key={i}
                        onClick={() => {
                          v?.reportName &&
                            vsf?.navigateTo(
                              `/report?id=${v?.reportId}&reportList=${JSON?.stringify(data1?.executInvalid?.filter((item) => item?.reportId)?.map((v) => v?.reportId))}&planPatientId=${planPatientId}`,
                            );
                        }}
                      >
                        <div className="item-info">
                          <div className="time1">
                            {dayjs(v?.reportTime)?.format('YYYY-MM-DD')}
                          </div>
                          <div
                            className={classNames(`time2 ellipsis1`, {
                              text: v?.abnormal,
                            })}
                          >
                            {v?.elementValue}
                          </div>
                          {currentHeader?.indicatorType !== 'LATEST' ? null : (
                            <div className="time3 ellipsis2">
                              {v?.referenceRanges ?? ''}
                            </div>
                          )}
                          <div className="time4">{v?.normalValueUnit}</div>
                        </div>
                        {v?.reportName ? (
                          <div className="text">来源报告：{v?.reportName}</div>
                        ) : (
                          ''
                        )}

                        {/* <div className="time">
                          {dayjs(v?.reportTime)?.format('YYYY-MM-DD')}
                        </div>

                        <div className="time">
                          {isImgSrc(v?.elementValue) ? (
                            <ImageUploader
                              value={[{ url: v?.elementValue ?? '' }]}
                              maxCount={1}
                              deletable={false}
                              showUpload={false}
                              disableUpload
                              upload={() => {
                                return new Promise((resolveOuter) => {
                                  resolveOuter(
                                    new Promise((resolveInner) => {}),
                                  );
                                });
                              }}
                            ></ImageUploader>
                          ) : (
                            // <img
                            //   className="table-img"
                            //   src={v?.elementValue}
                            // ></img>
                            <>
                              {v?.elementValue}
                              {v?.normalValueUnit ?? ''}
                            </>
                          )}
                        </div> */}
                      </div>
                    );
                  })}
                </div>
              )}
              {urlstate?.currentTab == 'chart' && (
                <div className="chart">
                  <div className="indicatorChart" id="indicatorChart"></div>
                  <div className="tag">
                    <img src={arrowLeft} alt="" />
                    外院数据
                  </div>
                  <div className="title">{currentHeader?.projectName}</div>
                  {initCharts && (
                    <div
                      className="charts"
                      onClick={() => setInitCharts(false)}
                    >
                      <img src={charts} alt="" />
                      <span>双指拖动放大缩小图形</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            ''
          )}
          {data1?.invalidWarn?.length == 0 &&
            data1?.executInvalid?.length == 0 &&
            renderEmpty()}
        </div>
      </div>
    </>
  );
};

export default definePage(IndicatorDetails);
