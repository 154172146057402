import './index.less';

import useUrlState from '@ahooksjs/use-url-state';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Box,
  ImageUploader,
  ImageViewer,
  Mask,
  NavBar,
  Step,
  Steps,
} from '@vs/vsf-mobile';
import { DownOutline, RightOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { ReactNode, useEffect, useState } from 'react';

import activeIndicator from '@/assets/activeIndicator.png';
import activeMineTab from '@/assets/activeMine.png';
import activeReport from '@/assets/activeReport.png';
import empty from '@/assets/empty1.png';
import err from '@/assets/err.png';
import mineIndicator from '@/assets/mineIndicator.png';
import report from '@/assets/report.png';
import step3_img from '@/assets/step/step3_img.png';
import step3_text from '@/assets/step/step3_text.png';
import succes from '@/assets/succes.png';
import DomTitle from '@/components/dom_title';
import { GenderEnum, PLANSTATUSENUM } from '@/config';
import { getENVIR, isImgSrc } from '@/utils';

import PickerPopup from './PickerPopup';
const size = 50;

interface IndicatorItemType {
  abnormal?: boolean;
  title?: string;
  value?: string | ReactNode;
  reportName?: string;
  time?: string;
  stateText?: string;
  data?: any;
  onClick?: (e) => void;
}

interface StepItemType {
  isSuspension?: boolean;
  isImg?: boolean;
  value?: any;
  title?: string;
  time?: string;
  onClickReport?: (e) => void;
}

const IndicatorItem: React.FC<IndicatorItemType> = (props) => {
  const {
    abnormal = true,
    title,
    value,
    reportName,
    time,
    stateText,
    data,
    onClick,
  } = props;
  return (
    <div
      className="IndicatorItem1"
      onClick={() => {
        onClick && onClick(data);
      }}
    >
      <div className="IndicatorItem-top">
        <div className="left ellipsis1">{title}</div>
        <div className="rigth">
          <span className="num">{value}</span>
          <span
            className={classNames(`state`, {
              errState: !abnormal,
            })}
          >
            {stateText}
          </span>
        </div>
      </div>
      <div className="IndicatorItem-bottom">
        {reportName && <div className="item">来源报告：{reportName}</div>}

        <div className="item">更新时间：{time}</div>
      </div>
    </div>
  );
};

const StepItem: React.FC<StepItemType> = (props) => {
  const {
    value,
    isSuspension = false,
    isImg = false,
    title,
    time,
    onClickReport,
  } = props;
  console.log(props, value);
  const isAbnormal = () => {
    return value?.reportDetailList?.find(
      (i) => i?.resultStatus == 'H' || i?.resultStatus == 'L',
    );
  };

  const handelClick = () => {
    if (isImg) {
      const handlerMulti = ImageViewer.Multi.show({
        images: value?.value,
        defaultIndex: 0,
      });
    } else {
      onClickReport && onClickReport(value);
    }
  };

  return (
    <div className="StepItem" onClick={handelClick}>
      <div className="StepItem-header">
        <div className="title ellipsis1">{title}</div>
        {/* 缺少判断字段 */}
        {!isImg && isAbnormal() && <div className="rigth">异常</div>}
      </div>
      <div
        className={classNames(`StepItem-content`, {
          imgContent: isImg,
        })}
      >
        {isImg ? (
          <>
            {value?.value?.map((v, i) => (
              <img
                className="imgContent-item"
                key={'imgContent' + i}
                src={v}
                onClick={(e) => {
                  e.stopPropagation();
                  const handlerMulti = ImageViewer.Multi.show({
                    images: value?.value,
                    defaultIndex: i,
                  });
                }}
              ></img>
            ))}
            {/* <ImageViewer
              // classNames={{
              //   mask: 'customize-mask',
              //   body: 'customize-body',
              // }}
              image={value?.value}
              // visible={visible}
              // onClose={() => {
              //   setVisible(false);
              // }}
            /> */}
          </>
        ) : (
          <div className="row">
            <div className="time">申请时间：{time}</div>
            <div
              className="text"
              onClick={() => {
                !isImg && onClickReport && onClickReport(value);
              }}
            >
              查看报告 <RightOutline />
            </div>
          </div>
        )}
      </div>
      {isSuspension && <div className="left-absolute">外院</div>}
    </div>
  );
};

const hanleJosnStr = (value, type: 'stringify' | 'parse') => {
  try {
    JSON?.parse;
    if (type == 'stringify') return JSON?.stringify(value);
    return JSON?.parse(value);
  } catch (error) {
    console.log(error);
  }
};

const Mine: React.FC<any> = (props) => {
  const [urlstate, setUrlState] = useUrlState(props?.routes?.query, {
    navigateMode: 'replace',
  });
  const [headerList, setHeaderList] = useState<any>([]);
  const [currentHeader, setCurrentHeader] = useState<any>();
  const [data, setData] = useState<any>([]);
  const [currentTab, setCurrentTab] = useState('indicator');
  const [reportList, setReportList] = useState<any>([]);
  const [currentRepor, setCurrentRepor] = useState<any>(
    hanleJosnStr(urlstate?.currentRepor, 'parse') ?? [
      { value: '全部', label: '全部' },
    ],
  );

  const { plan } = props.stores;

  const { setStep, step, setPatientPlanTask } = plan;
  useEffect(() => {
    // handleGetMineInfo();
    handleInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentHeader?.id) {
      // handleGetMineInfo();
      handleReortList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHeader?.id]);

  useEffect(() => {
    console.log(currentHeader?.id);
    if (currentHeader?.id) getUnExecuteTasks1();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHeader?.id]);

  const handleInfo = async () => {
    const res = await vsf?.services?.RagPlanController_getPatients_3a45e8({});
    if (res?.code == 200) {
      const data = res?.data;
      setHeaderList(res?.data);
      if (urlstate?.planPatientId) {
        setCurrentHeader(
          res?.data?.find((v) => v?.id == urlstate?.planPatientId),
        );
        const dom = document?.getElementById(urlstate?.planPatientId);
        dom && dom?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        const value = data?.filter((v) => v?.status == 'EXECUTE');
        setCurrentHeader(value?.[0] ?? data?.[0]);
      }
    }
  };

  // 查询个案详情--待处理
  const getUnExecuteTasks1 = async () => {
    const res =
      await vsf.services?.RagPlanController_getPatientPlanTaskExecuteElement_552d18(
        {
          patientId: currentHeader?.id,
        },
      );
    if (res.code === 200) {
      setData(res.data);
      setPatientPlanTask(res.data);
    }
  };

  const mergeAndSortByDate = (array1, array2) => {
    const mergedArray = array1?.concat(array2);
    mergedArray?.sort((a, b) => {
      return dayjs(a.applyDate).isAfter(dayjs(b.applyDate));
    });
    return mergedArray;
  };

  const handleReortList = async () => {
    const res = await vsf?.services?.PlanController_queryMyReport_a13f6e({
      patientId: currentHeader?.id,
    });
    if (res?.code == 200) {
      const data = res?.data;
      const { planTaskExecuteWithElementDtos, testReportItemDtos } = data;
      const suspensionList = (planTaskExecuteWithElementDtos ?? [])?.map(
        (v) => {
          return {
            ...v,
            isSuspension: true,
            testItemCode: v?.elementId,
            testItemName: v?.elementId,
            value: renderImgList(v?.elementValue),
            testReportItemBaseList: [
              {
                ...v,
                isSuspension: true,
                testItemCode: v?.elementId,
                testItemName: v?.elementId,
                value: renderImgList(v?.elementValue),
              },
            ],
          };
        },
      );
      // mergeAndSortByDate(suspensionList ?? [], testReportItemDtos ?? []);
      setReportList(
        mergeAndSortByDate(suspensionList ?? [], testReportItemDtos ?? []),
      );
    }
  };

  const handleJumpDetails = (value?: any) => {
    setUrlState({ planPatientId: currentHeader?.id });
    if (value)
      return vsf?.navigateTo(
        `/indicator_details?id=${value?.elementId}&planPatientId=${currentHeader?.id}`,
      );
    vsf?.navigateTo(`/indicator_details?planPatientId=${currentHeader?.id}`);
  };

  const handelStepEnd = () => {
    setStep(null);
  };

  const handleClickReport = (value) => {
    setUrlState({
      currentRepor: hanleJosnStr(currentRepor, 'stringify'),
      planPatientId: currentHeader?.id,
    });
    vsf?.navigateTo(
      `/report?id=${value?.id}&reportList=${JSON?.stringify(reportList?.map((v) => v?.id))}&planPatientId=${currentHeader?.id}`,
    );
  };

  const handleClickReportChange = (value) => {
    const find = currentRepor?.find((v) => v?.value == value?.value);
    if (find) {
      const activeList = currentRepor?.filter((v) => v?.value != value?.value);
      if (activeList.length) {
        setCurrentRepor(activeList);
      } else {
        setCurrentRepor([{ value: '全部', label: '全部' }]);
      }
    } else {
      const all = currentRepor?.find((v) => v?.value == '全部');
      if (all) {
        setCurrentRepor([value]);
      } else {
        setCurrentRepor([...currentRepor, value]);
      }
    }
  };

  const renderindicatorsList = (value, type = 'abnormol') => {
    const indicatorsAllList = data;
    if (type == 'abnormol')
      return indicatorsAllList?.filter((v) => v?.abnormal);
    return indicatorsAllList?.filter((v) => !v?.abnormal);
  };

  const renderStr = (value, num = 6) => {
    if (value?.length > num) {
      return `${value?.substring(0, num)}...`;
    }
    return value;
  };

  const renderImgList = (value) => {
    try {
      return value?.split(',');
    } catch (error) {
      console.log(error);
    }
  };

  const renderEmpty = (text = '暂无指标记录') => {
    return (
      <div className="render-mine-empty">
        <img src={empty} className="render-img"></img>
        <div className="render-empty-text">{text}</div>
      </div>
    );
  };

  const renderIsImg = (value) => {
    return isImgSrc(value?.elementValue) ? (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ImageUploader
          value={[{ url: value?.elementValue }]}
          maxCount={1}
          deletable={false}
          showUpload={false}
          disableUpload
          upload={() => {
            return new Promise((resolveOuter) => {
              resolveOuter(new Promise((resolveInner) => {}));
            });
          }}
        ></ImageUploader>
      </div>
    ) : (
      <div style={{ display: 'inline-flex' }}>
        <span>{value?.elementValue}</span>
        {value?.normalValueUnit ? (
          <span style={{ marginLeft: 4 }}>{value?.normalValueUnit}</span>
        ) : null}
      </div>
    );
  };

  const renderText = (value) => {
    return typeof value?.abnormal == 'boolean' && !isImgSrc(value?.elementValue)
      ? '正常'
      : '';
  };

  const renderIndicator = () => {
    if (
      renderindicatorsList(currentHeader)?.length > 0 ||
      renderindicatorsList(currentHeader, 'normal')?.length > 0
    ) {
      return (
        <div className="indicators">
          <div className="indicators-header">
            <div className="header-title">我的指标</div>
            <div
              className="header-rigth"
              onClick={() => {
                handleJumpDetails();
              }}
            >
              <span className="text">指标记录</span>
              <RightOutline fontSize={12} color="#A7AFBA" className="icon" />
            </div>
          </div>
          <div className="indicators-content">
            <div className="indicators-abnormol mine-col">
              {renderindicatorsList(currentHeader)?.length > 0 ? (
                <>
                  <div className="indicators-title mine-col">异常项</div>
                  <div className="abnormol-content">
                    {renderindicatorsList(currentHeader)?.map((v, index) => {
                      return (
                        <div
                          className="abnormol-item"
                          key={index}
                          onClick={() => {
                            handleJumpDetails(v);
                          }}
                        >
                          <div className="item-title">
                            {renderStr(v?.projectName)}
                          </div>
                          <div className="item-num">{renderIsImg(v)}</div>
                          <div className="abnormol-state">异常</div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
            <div className="indicators-abnormol mine-col">
              {renderindicatorsList(currentHeader, 'normal')?.length > 0 ? (
                <>
                  <div className="indicators-title mine-col">标准项</div>
                  <div className="abnormol-content normol">
                    {renderindicatorsList(currentHeader, 'normal')?.map(
                      (v, index) => {
                        return (
                          <div
                            className="abnormol-item"
                            key={index}
                            onClick={() => {
                              handleJumpDetails(v);
                            }}
                          >
                            <div className="item-title">
                              {renderStr(v?.projectName)}
                            </div>
                            <div className="item-num">{renderIsImg(v)}</div>
                            <div className="abnormol-state normol-text">
                              {renderText(v)}
                            </div>
                          </div>
                        );
                      },
                    )}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return renderEmpty();
    }
  };
  const renderSafeArea = () => {
    if (getENVIR()?.includes('wx')) {
      const operatingSystem = navigator.userAgent;
      const isiOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
      const isAndroid = navigator.userAgent.match(/Android/i);
      console.log(
        operatingSystem,
        `ios:${isiOS}`,
        `Android:${isAndroid}`,
        'operatingSystem',
      );
      return isiOS ? '22px' : '0px';
    }
  };

  const renderTabList = (value) => {
    const list = value
      ?.map((v) => v?.testReportItemBaseList)
      ?.flat()
      ?.map((v) => v?.testItemCode);
    return Array.from(new Set(list)) as any;
  };

  const renderReportList = (currentRepor) => {
    const list = reportList.sort((a, b) => {
      return dayjs(a?.isSuspension ? a.createdAt : a.reportDate)?.isBefore(
        b?.isSuspension ? b.createdAt : b.reportDate,
      )
        ? 1
        : -1;
    });
    const allRepor = currentRepor?.find((v) => v?.value == '全部');
    if (allRepor) return list;
    return list.filter((item) =>
      currentRepor.find(
        (_c) =>
          _c.value === item.testItemCode ||
          item.testReportItemBaseList?.find(
            (_t) => _t.testItemCode === _c.value,
          ),
      ),
    );
  };
  return (
    <>
      <NavBar children="我的" backArrow={false} />
      <DomTitle title="我的" />
      <div className="mine-container">
        <div className="mine-header mine-col">
          {headerList?.map((v, index) => (
            <div
              className={classNames(`header-item`, {
                headerItemActive: currentHeader?.id == v?.id,
              })}
              key={index}
              id={v?.id}
              onClick={() => setCurrentHeader(v)}
            >
              {v?.patientName}
            </div>
          ))}
        </div>
        <div className="mine-content">
          <div className="mine-info mine-col">
            <div className="info-name">{currentHeader?.patientName}</div>
            <div className="info-contnet">
              <div className="info-col">
                {GenderEnum?.[currentHeader?.patientGender]}
              </div>
              <div className="info-col">{currentHeader?.age}岁</div>
              <div className="info-col col-num">{currentHeader?.phone}</div>
            </div>
          </div>
          <div className="mine-indicator">
            <div className="indicator-header">
              <div
                className={classNames(`header-item`, {
                  activeItem: currentTab == 'indicator',
                })}
                onClick={() => setCurrentTab('indicator')}
              >
                <img
                  className="icon"
                  src={
                    currentTab == 'indicator' ? activeIndicator : mineIndicator
                  }
                ></img>
                <div>我的指标</div>
              </div>
              <div
                className={classNames(`header-item`, {
                  activeItem: currentTab == 'report',
                })}
                onClick={() => setCurrentTab('report')}
              >
                <img
                  className="icon"
                  src={currentTab == 'report' ? activeReport : report}
                ></img>
                <div>我的报告</div>
              </div>
            </div>
            {currentTab == 'indicator' && (
              <>
                {renderindicatorsList(currentHeader)?.length > 0 && (
                  <div className="err-content">
                    <div className="err-header">
                      <img className="icon" src={err}></img>
                      <div className="text">
                        {renderindicatorsList(currentHeader)?.length}项异常指标
                      </div>
                    </div>
                    {renderindicatorsList(currentHeader)?.map((v, i) => {
                      return (
                        <IndicatorItem
                          abnormal={false}
                          key={'Abnormal' + i}
                          title={v?.projectName}
                          value={renderIsImg(v)}
                          time={v?.reportTime}
                          reportName={v?.reportName}
                          stateText="异常"
                          data={v}
                          onClick={handleJumpDetails}
                        ></IndicatorItem>
                      );
                    })}
                  </div>
                )}
                {renderindicatorsList(currentHeader, 'normol')?.length > 0 && (
                  <div className="success-content">
                    <div className="success-header">
                      <img className="icon" src={succes}></img>
                      <div className="text">
                        {renderindicatorsList(currentHeader, 'normol')?.length}
                        项放心指标
                      </div>
                    </div>
                    {renderindicatorsList(currentHeader, 'normol')?.map(
                      (v, i) => {
                        return (
                          <IndicatorItem
                            abnormal={true}
                            key={'Abnormal' + i}
                            title={v?.projectName}
                            value={renderIsImg(v)}
                            time={v?.reportTime}
                            reportName={v?.reportName}
                            stateText={renderText(v)}
                            data={v}
                            onClick={handleJumpDetails}
                          ></IndicatorItem>
                        );
                      },
                    )}
                  </div>
                )}
                {renderindicatorsList(currentHeader, 'normol')?.length == 0 &&
                  renderindicatorsList(currentHeader)?.length == 0 &&
                  renderEmpty()}
              </>
            )}
            {currentTab == 'report' && (
              <>
                {reportList?.length > 0 && (
                  <div className="report-content">
                    <div className="report-header">
                      <div className="left">
                        <div
                          className={classNames(`report-item`, {
                            activeReport: currentRepor?.find(
                              (item) => item?.value == '全部',
                            ),
                          })}
                          onClick={() => {
                            setCurrentRepor([{ value: '全部', label: '全部' }]);
                          }}
                        >
                          全部
                        </div>
                        {renderTabList(reportList)?.map((v, i) => (
                          <div
                            className={classNames(`report-item`, {
                              activeReport: currentRepor?.find(
                                (item) => item?.value == v,
                              ),
                            })}
                            key={'report' + i}
                            onClick={() => {
                              handleClickReportChange({ label: v, value: v });
                            }}
                          >
                            {renderStr(v, 10)}
                          </div>
                        ))}
                      </div>
                      <PickerPopup
                        containerClassName="rigth"
                        isCheckbox
                        list={renderTabList(reportList)?.map((v) => {
                          return {
                            label: v,
                            value: v,
                          };
                        })}
                        defaultValue={currentRepor}
                        onChange={(value) => {
                          if (value?.length == 0)
                            return setCurrentRepor([
                              { value: '全部', label: '全部' },
                            ]);
                          const all = value?.filter((v) => v?.value !== '全部');
                          setCurrentRepor(all);
                        }}
                      >
                        筛选 <DownOutline className="icon" />
                      </PickerPopup>
                    </div>
                    <div className="report-step">
                      <Steps direction="vertical">
                        {renderReportList(currentRepor)?.map((v, i) => (
                          <Step
                            title={`${v?.isSuspension ? '上传时间：' + v?.createdAt ?? '' : '报告时间：'}${v?.reportDate ?? ''}`}
                            status="finish"
                            key={'Step' + i}
                            description={
                              <StepItem
                                isSuspension={v?.isSuspension}
                                isImg={v?.isSuspension}
                                value={v}
                                title={v?.reportName ?? v.projectName}
                                time={v?.applyDate}
                                onClickReport={handleClickReport}
                              />
                            }
                          />
                        ))}
                        {renderReportList(currentRepor)?.length == 0 &&
                          renderEmpty('')}
                      </Steps>
                    </div>
                  </div>
                )}
                {reportList?.length == 0 && renderEmpty('暂无报告')}
              </>
            )}
          </div>
        </div>
      </div>
      <Mask visible={step === 3}>
        <div className="step3">
          <img className="step3_img" src={step3_img} alt="" />
          <div className="step3_btn" onClick={handelStepEnd}>
            我知道了
          </div>
          <div className="box">
            <div className="box_con"></div>
            <div className="box_line">
              <img className="step3_text" src={step3_text} alt="" />
            </div>
          </div>
        </div>
        <div className="step3_tabBar_item" style={{ bottom: renderSafeArea() }}>
          <img src={activeMineTab} alt="" />
          <div className="tabBar_item_text">我的</div>
        </div>
      </Mask>
    </>
  );
};

export default definePage(Mine);
