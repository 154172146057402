import './index.less';

import useUrlState from '@ahooksjs/use-url-state';
import vsf, { definePage } from '@vs/vsf-boot';
import { Box, ImageUploader, NavBar } from '@vs/vsf-mobile';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import empty from '@/assets/empty1.png';
import err from '@/assets/err.png';
import patient_menu1 from '@/assets/patient_menu1.png';
import patient_menu2 from '@/assets/patient_menu2.png';
import patient_menu3 from '@/assets/patient_menu3.png';
import patient_menu4 from '@/assets/patient_menu4.png';
import succes from '@/assets/succes.png';
import user_avatar_png from '@/assets/user_avatar.png';
import ChangePlanPopup from '@/components/changePlanPopup';
import DomTitle from '@/components/dom_title';
import { GenderEnum, PLANSTATUSENUM } from '@/config';
import { cardHide, isImgSrc } from '@/utils';
interface IndicatorItemType {
  abnormal?: boolean;
  title?: string;
  value?: string | ReactNode;
  reportName?: string;
  time?: string;
  stateText?: string;
  data?: any;
  onClick?: (e) => void;
}

const IndicatorItem: React.FC<IndicatorItemType> = (props) => {
  const {
    abnormal = true,
    title,
    value,
    reportName,
    time,
    stateText,
    data,
    onClick,
  } = props;
  return (
    <div
      className="IndicatorItem1"
      onClick={() => {
        onClick && onClick(data);
      }}
    >
      <div className="IndicatorItem-top">
        <div className="left ellipsis1">{title}</div>
        <div className="rigth">
          <span className="num">{value}</span>
          <span
            className={classNames(`state`, {
              errState: !abnormal,
            })}
          >
            {stateText}
          </span>
        </div>
      </div>
      <div className="IndicatorItem-bottom">
        {reportName && <div className="item">来源报告：{reportName}</div>}

        <div className="item">更新时间：{time}</div>
      </div>
    </div>
  );
};

const Mine: React.FC<any> = (props) => {
  const { planId, patientId } = props.routes.query;
  const [currentPatient, setCurrentPatient] = useState<any>();
  const [data, setData] = useState<any>([]);
  const [currentPlan, setCurrentPlan] = useState<any>({
    id: planId ? Number(planId) : planId,
  });
  const { plan } = props.stores;
  const { setPatientPlanTask } = plan;

  // 查询个案详情--待处理
  const getUnExecuteTasks1 = useCallback(async () => {
    const res =
      await vsf.services?.RagPlanController_getPatientPlanTaskExecuteElement_552d18(
        {
          patientId: patientId,
          planMasterId: currentPlan?.id,
        },
      );
    if (res.code === 200) {
      setData(res.data);
      setPatientPlanTask(res.data);
    }
  }, [patientId, currentPlan?.id, setPatientPlanTask]);

  const getPatientManagerVoById = useCallback(async () => {
    const res =
      await vsf.services?.EmployeePatientController_getPatientManagerVoById_edbb80(
        {
          id: patientId,
        },
      );
    if (res.code === 200) {
      setCurrentPatient(res.data);
    }
  }, [patientId]);

  useEffect(() => {
    getUnExecuteTasks1();
    getPatientManagerVoById();
  }, [getUnExecuteTasks1, getPatientManagerVoById]);
  const handleJumpDetails = (value?: any) => {
    if (value)
      return vsf?.navigateTo(
        `/doctor/indicator_details?elementId=${value?.elementId}&patientId=${currentPatient?.id}&planId=${currentPlan?.id}&planTitle=${currentPlan?.currentPlanPathway?.title}`,
      );
    vsf?.navigateTo(
      `/doctor/indicator_details?patientId=${currentPatient?.id}&planId=${currentPlan?.id}&planTitle=${currentPlan?.currentPlanPathway?.title}`,
    );
  };
  const toOverview = () => {
    vsf.showToast('暂未开放');
  };
  const toGuide = () => {
    vsf?.navigateTo(`/doctor/guide?planId=${planId}&patientId=${patientId}`);
  };
  const toReport = () => {
    vsf?.navigateTo(
      `/doctor/patient_report?planId=${planId}&patientId=${patientId}`,
    );
  };
  const toTask = () => {
    vsf?.navigateTo(`/doctor/task?planId=${planId}&patientId=${patientId}`);
  };

  const renderindicatorsList = (value, type = 'abnormol') => {
    const indicatorsAllList = data;
    if (type == 'abnormol')
      return indicatorsAllList?.filter((v) => v?.abnormal);
    return indicatorsAllList?.filter((v) => !v?.abnormal);
  };

  const renderEmpty = (text = '暂无指标记录') => {
    return (
      <div className="render-patient-empty">
        <img src={empty} className="render-img"></img>
        <div className="render-empty-text">{text}</div>
      </div>
    );
  };

  const renderIsImg = (value) => {
    return isImgSrc(value?.elementValue) ? (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ImageUploader
          value={[{ url: value?.elementValue }]}
          maxCount={1}
          deletable={false}
          showUpload={false}
          disableUpload
          upload={() => {
            return new Promise((resolveOuter) => {
              resolveOuter(new Promise((resolveInner) => {}));
            });
          }}
        ></ImageUploader>
      </div>
    ) : (
      <div style={{ display: 'inline-flex' }}>
        <span>{value?.elementValue}</span>
        {value?.normalValueUnit ? (
          <span style={{ marginLeft: 4 }}>{value?.normalValueUnit}</span>
        ) : null}
      </div>
    );
  };

  const renderText = (value) => {
    return typeof value?.abnormal == 'boolean' && !isImgSrc(value?.elementValue)
      ? '正常'
      : '';
  };

  return (
    <>
      <NavBar children="患者资料" backArrow={false} />
      <DomTitle title="患者资料" />
      <div className="patient-container">
        <div className="bg">
          <div className="patient-content">
            <div className="patient_info">
              <div className="avatar">
                <img src={user_avatar_png} alt="" />
              </div>
              <div className="patient_info_content">
                <div className="user_name_box">
                  <div className="user_name">
                    {currentPatient?.patientName ?? '-'}
                  </div>
                  {
                    <div className="user_gender">
                      {currentPatient?.patientGender
                        ? GenderEnum[currentPatient?.patientGender]
                        : '-'}
                    </div>
                  }
                  <div className="user_age">
                    {currentPatient?.birthday
                      ? dayjs().year() - dayjs(currentPatient?.birthday).year()
                      : '-'}
                    岁
                  </div>
                </div>
                <div className="id_card">
                  身份证号：
                  {currentPatient?.idNum
                    ? cardHide(currentPatient?.idNum)
                    : '-'}
                </div>
              </div>
            </div>
            <div className="patient-menu">
              <div className="patient-menu-item" onClick={toOverview}>
                <img src={patient_menu1} className="patient-menu-img"></img>
                <div className="patient-menu-text">全息视图</div>
              </div>
              <div className="patient-menu-item" onClick={toGuide}>
                <img src={patient_menu2} className="patient-menu-img"></img>
                <div className="patient-menu-text">专病指导</div>
              </div>
              <div className="patient-menu-item" onClick={toReport}>
                <img src={patient_menu3} className="patient-menu-img"></img>
                <div className="patient-menu-text">报告查询</div>
              </div>
              <div className="patient-menu-item" onClick={toTask}>
                <img src={patient_menu4} className="patient-menu-img"></img>
                <div className="patient-menu-text">专病任务</div>
              </div>
            </div>
            <div className="nav_title">
              <div className="left">指标记录</div>
              <div className="right">
                <ChangePlanPopup
                  planId={currentPlan?.id}
                  patientId={patientId}
                  onChange={(val) => setCurrentPlan(val)}
                />
              </div>
            </div>
            <div className="patient-indicator">
              {renderindicatorsList(currentPatient)?.length > 0 && (
                <div className="err-content">
                  <div className="err-header">
                    <img className="icon" src={err}></img>
                    <div className="text">
                      {renderindicatorsList(currentPatient)?.length}项异常指标
                    </div>
                  </div>
                  {renderindicatorsList(currentPatient)?.map((v, i) => {
                    return (
                      <IndicatorItem
                        abnormal={false}
                        key={'Abnormal' + i}
                        title={v?.projectName}
                        value={renderIsImg(v)}
                        time={v?.reportTime}
                        reportName={v?.reportName}
                        stateText="异常"
                        data={v}
                        onClick={handleJumpDetails}
                      ></IndicatorItem>
                    );
                  })}
                </div>
              )}
              {renderindicatorsList(currentPatient, 'normol')?.length > 0 && (
                <div className="success-content">
                  <div className="success-header">
                    <img className="icon" src={succes}></img>
                    <div className="text">
                      {renderindicatorsList(currentPatient, 'normol')?.length}
                      项放心指标
                    </div>
                  </div>
                  {renderindicatorsList(currentPatient, 'normol')?.map(
                    (v, i) => {
                      return (
                        <IndicatorItem
                          abnormal={true}
                          key={'Abnormal' + i}
                          title={v?.projectName}
                          value={renderIsImg(v)}
                          time={v?.reportTime}
                          reportName={v?.reportName}
                          stateText={renderText(v)}
                          data={v}
                          onClick={handleJumpDetails}
                        ></IndicatorItem>
                      );
                    },
                  )}
                </div>
              )}
              {renderindicatorsList(currentPatient, 'normol')?.length == 0 &&
                renderindicatorsList(currentPatient)?.length == 0 &&
                renderEmpty()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default definePage(Mine);
