import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Button, PullToRefresh } from '@vs/vsf-mobile';
import React, { useEffect, useState } from 'react';

import { CareOrderStatusEnum } from '@/config';
import { getIntegerAndDecimal } from '@/utils';
const OrderListDom: any = ({ orderStatus }) => {
  const [data, setData] = useState<OrderRecordTeamPatientVo03Ec0D[] | any>([]);
  const [hasMore, setHasMore] = useState(false);

  const getDoctorOrderList = async () => {
    const res =
      await vsf.services.OrderRecordController_getPatientOrderList_c16c1c({
        orderStatus,
      });
    if (res.code === 200) {
      setData(res?.data ?? []);
      setHasMore(res?.data?.length === 10);

      return res.data;
    }
  };
  useEffect(() => {
    getDoctorOrderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatus]);

  return (
    <div className="order_list_dom">
      <PullToRefresh onRefresh={getDoctorOrderList}>
        <div className="list">
          {data.map((item, index) => (
            <div
              className="card"
              key={item.id}
              onClick={() =>
                vsf.navigateTo(`/specialist_serrvice_detail?orderId=${item.id}`)
              }
            >
              <div className="header">
                <span className="title">{item.bundleName}</span>
                <span
                  className={`state ${item.orderStatus === 'WAIT_PAY' ? 'state1' : 'state2'}`}
                >
                  {item.orderStatus
                    ? CareOrderStatusEnum[item.orderStatus]
                    : null}
                </span>
              </div>
              <div className="line"></div>
              <div className="infos">
                <span className="label">服务对象</span>
                <span className="value">
                  {item.visitPatient?.patientName ?? '-'}
                </span>
              </div>
              {!item?.serveBundle?.experienceFlag && (
                <div className="infos">
                  <span className="label">医生/团队</span>
                  <span className="value">
                    {item.bundleTeamType === 'TEAM'
                      ? item.serveTeam?.teamName ?? '-'
                      : item.serveDoctor?.doctorName ?? '-'}
                  </span>
                </div>
              )}
              <div className="infos">
                <span className="label">服务周期</span>
                <span className="value">
                  {item.servePeriod ? item.servePeriod + '天' : '-'}
                </span>
              </div>
              <div className="line"></div>

              <div className="price_box">
                <div className="label">服务费用</div>
                <div className="price">
                  <span className="unit">￥</span>
                  {item.roundingCharge ? (
                    <>
                      <span className="price_integer">
                        {getIntegerAndDecimal(item.roundingCharge).integer}.
                      </span>
                      <span className="price_fractional">
                        {getIntegerAndDecimal(item.roundingCharge).decimal}
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
                {/* {item.orderStatus === 'WAIT_PAY' ? (
                  <Button className="pay_btn">立即支付</Button>
                ) : item.orderStatus === 'xx' ? (
                  <Button className="pay_btn1">去评价</Button>
                ) : null} */}
              </div>
            </div>
          ))}
        </div>
        {!data?.length && <div className="empty">暂无数据</div>}
        {!hasMore && !!data?.length && (
          <div className="empty">我是有底线的~</div>
        )}
      </PullToRefresh>
    </div>
  );
};

export default definePage(OrderListDom);
