import { createServices } from '@vs/vsf-boot';
export default createServices({
  RagPlanController_getPlanTestIndicator_2f84be: {
    method: 'post',
    url: '/api/rag-plan/get-plan-test-indicator',
    parameterFomatter: (data?: { planMasterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: PlanMasterPatientAbnormalElementVoDe76C9,
    ) => data,
  },
});
