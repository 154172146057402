import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, Button, NavBar, Popup, Picker } from '@vs/vsf-mobile';
import React, { useEffect, useState } from 'react';
import {
  getIntegerAndDecimal,
  getIntegerAndDecimalNum,
  isNumber,
} from '@/utils';
import { TITLEEnum } from '@/config';
import del from '@/assets/del.png';
import down from '@/assets/down.png';
import empty from '@/assets/empty.png';
import doctorImg from '@/assets/doctor.png';
import classNames from 'classnames';

const sortTypeList = [
  {
    label: '默认排序',
    value: 'default',
  },
  // {
  //   label: '按好评排序',
  //   value: 'default'
  // },
  // {
  //   label: '按服务量排序',
  //   value: 'default'
  // },
  {
    label: '按专家职称排序',
    value: 'titleCode',
  },
  {
    label: '按价格排序',
    value: 'servePrice',
  },
];
const ServiceListPopupButton = ({
  children,
  style,
  className,
  serveBundleId,
  experienceFlag,
  doctorId,
}) => {
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [specialistDetailId, setSpecialistDetailId] = useState();
  const [departmentIds, setDepartmentIds] = useState([]);
  const [serveBundleSpecialistContrast, setServeBundleSpecialistContrast] =
    useState([]);
  const [bundleTeamLeader, setBundleTeamLeader] = useState([]);
  const [department, setDepartment] = useState();
  const [sortType, setSortType] = useState();
  const reqdepartmentId = async () => {
    const res =
      await vsf.services?.InstitutionInfoController_getAllByInstitutionDepartmentPinyinQto_d8ca77?.(
        {
          qto: {},
        },
      );
    if (res.code === 200 && res?.data) setDepartmentIds(res?.data ?? []);
  };
  const getBundleTeamLeaderByServeBundleId = async () => {
    const res =
      await vsf.services?.BundleTeamController_getBundleTeamLeaderByServeBundleId_36e1eb?.(
        {
          serveBundleId,
        },
      );
    if (res.code === 200 && res?.data) setBundleTeamLeader(res?.data);
  };

  const filterList = () => {
    let data = [...bundleTeamLeader];
    if (doctorId) {
      data = data.filter((item) => {
        if (item.bundleTeamType === 'DOCTOR') {
          return item.serveDoctor?.id == doctorId;
        } else if (item.bundleTeamType === 'TEAM') {
          return item.serveTeam?.teamDoctorId?.find((item) => item == doctorId);
        }
      });
    }
    if (department?.[0]) {
      data = data.filter((item) => {
        if (item?.bundleTeamType === 'TEAM') {
          return (
            item?.serveTeam?.teamLeader?.department?.id === department?.[0]
          );
        } else {
          return item?.serveDoctor?.department?.id === department?.[0];
        }
      });
    }
    if (sortType?.[0]) {
      switch (sortType?.[0]) {
        case 'titleCode':
          {
            data.sort(
              (a, b) =>
                (a.serveTeam?.teamLeader?.titleCode ||
                  a.serveDoctor?.titleCode) -
                (b.serveTeam?.teamLeader?.titleCode ||
                  b.serveDoctor?.titleCode),
            );
          }
          break;
        case 'servePrice':
          data.sort((a, b) => a.servePrice - b.servePrice);
          break;
        default:
          break;
      }
    }
    return data;
  };

  // 0元体验包逻辑
  const isExperienceFlagOpen =()=> {
    if(experienceFlag && filterList()?.[0]) {
      vsf.navigateTo(
        specialistDetailId
          ? `/order_confirmation?id=${filterList()?.[0]?.id}&experienceFlag=${experienceFlag}&specialistDetailId=${specialistDetailId}`
          : `/order_confirmation?id=${filterList()?.[0]?.id}&experienceFlag=${experienceFlag}`,
      )
    }else {
      setVisible(true);
    }
  }

  const getListServeBundleSpecialistContrastVoByServeBundleId = async () => {
    const res =
      await vsf.services?.ServeBundleContrastController_getListServeBundleSpecialistContrastVoByServeBundleId_ae8766?.(
        {
          serveBundleId,
        },
      );
    if (res.code === 200) {
      if (res?.data?.length) {
        setServeBundleSpecialistContrast(res?.data ?? []);
        setVisible3(true);
      } else {
        isExperienceFlagOpen()
      }
    }
  };

  const handelClick = () => {
    getListServeBundleSpecialistContrastVoByServeBundleId();
  };

  useEffect(() => {
    reqdepartmentId();
    getBundleTeamLeaderByServeBundleId();
  }, []);

  const renderEmpty = () => {
    return (
      <div className="empty">
        <img src={empty} className="img"></img>
        <div className="empty-text">暂无数据</div>
      </div>
    );
  };

  const renderSrcEmpty = (src) => {
    if (!src) return doctorImg;
    if (src?.length == 0) return doctorImg;
    return src;
  };

  return (
    <>
      <Button style={style} className={className} onClick={handelClick}>
        {children}
      </Button>
      <Popup
        visible={visible3}
        onMaskClick={() => {
          setVisible3(false);
        }}
        onClose={() => {
          setVisible3(false);
        }}
        className="service_popup"
        bodyStyle={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          minHeight: '40vh',
          maxHeight: '70vh',
          backgroundColor: '#F8F8F8',
        }}
      >
        <div className="check_popup_content">
          <div className="header">
            <div className="headeer_top">
              <div className="title">温馨提示</div>
              <span className="close_box" onClick={() => setVisible3(false)}>
                <img src={del} className="close"></img>
              </span>
            </div>
          </div>

          <div className="check_info">
            该服务对象为诊断“哮喘”的病人，请确认您孩子的诊断是：
          </div>
          <div className="check_info_list">
            {serveBundleSpecialistContrast?.map((item) => (
              <div
                className={classNames([
                  'check_info_item',
                  'ellipsis1',
                  {
                    select: item.specialistDetail?.id === specialistDetailId,
                  },
                ])}
                onClick={() => setSpecialistDetailId(item.specialistDetail?.id)}
                key={item.id}
              >
                {item.specialistDetail?.name}
              </div>
            ))}
          </div>
          <Button
            onClick={() => {
              setVisible3(false);
              isExperienceFlagOpen();
            }}
            className={['check_btn']}
            disabled={!specialistDetailId}
          >
            确认
          </Button>
        </div>
      </Popup>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        className="service_popup"
        bodyStyle={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          minHeight: '40vh',
          maxHeight: '70vh',
          backgroundColor: '#F8F8F8',
        }}
      >
        <div className="service_popup_content">
          <div className="header">
            <div className="headeer_top">
              <div className="title">
                请选择{experienceFlag ? '主治医师' : '医护服务'}
              </div>
              <span className="close_box" onClick={() => setVisible(false)}>
                <img src={del} className="close"></img>
              </span>
            </div>
            <div className="select_box">
              <Picker
                columns={[
                  [
                    { departmentName: '所有科室', id: null },
                    ...departmentIds.filter((d) =>
                      bundleTeamLeader.find((item) => {
                        return item.bundleTeamType === 'TEAM'
                          ? item.serveTeam?.teamLeader?.department?.id === d.id
                          : item.serveDoctor?.department?.id === d.id;
                      }),
                    ),
                  ]?.map((item) => ({
                    label: item.departmentName,
                    value: item.id,
                  })),
                ]}
                visible={visible1}
                onClose={() => {
                  setVisible1(false);
                }}
                value={department}
                onConfirm={(v) => {
                  console.log(v);
                  setDepartment(v);
                }}
              >
                {(items, { open }) => {
                  return (
                    <div
                      className="select_item"
                      onClick={() => setVisible1(!visible1)}
                    >
                      <span className="select_name">
                        {items.every((item) => item === null)
                          ? '所有科室'
                          : items[0]?.label ?? '所有科室'}
                      </span>
                      <img
                        src={down}
                        className={
                          visible1
                            ? 'select_icon select_icon_active'
                            : 'select_icon'
                        }
                      ></img>
                    </div>
                  );
                }}
              </Picker>
              <Picker
                columns={[sortTypeList]}
                visible={visible2}
                onClose={() => {
                  setVisible2(false);
                }}
                value={sortType}
                onConfirm={(v) => {
                  setSortType(v);
                }}
              >
                {(items, { open }) => {
                  return (
                    <div
                      className="select_item"
                      onClick={() => setVisible2(!visible2)}
                    >
                      <span className="select_name">
                        {items.every((item) => item === null)
                          ? '默认排序'
                          : items[0]?.label ?? '默认排序'}
                      </span>
                      <img
                        src={down}
                        className={
                          visible2
                            ? 'select_icon select_icon_active'
                            : 'select_icon'
                        }
                      ></img>
                    </div>
                  );
                }}
              </Picker>
            </div>
          </div>
          <div className="service_list">
            {filterList()?.length
              ? filterList()?.map((item) => {
                  return (
                    <div
                      className="service_item"
                      key={item.id}
                      onClick={() =>
                        vsf.navigateTo(
                          specialistDetailId
                            ? `/order_confirmation?id=${item.id}&specialistDetailId=${specialistDetailId}`
                            : `/order_confirmation?id=${item.id}`,
                        )
                      }
                    >
                      <div className="avatar">
                        <img
                          src={
                            item.bundleTeamType === 'TEAM'
                              ? renderSrcEmpty(
                                  item.serveTeam?.teamLeader?.avatarImg,
                                )
                              : renderSrcEmpty(item.serveDoctor?.avatarImg)
                          }
                          alt=""
                        />
                      </div>
                      <div className="info">
                        <div className="name_box">
                          <span className="name">
                            {item.bundleTeamType === 'TEAM'
                              ? item.serveTeam?.teamLeader?.doctorName ?? '-'
                              : item.serveDoctor?.doctorName ?? '-'}
                          </span>
                          <span className="posts">
                            {TITLEEnum[
                              item.bundleTeamType === 'TEAM'
                                ? item.serveTeam?.teamLeader?.title
                                : item.serveDoctor?.title
                            ] ?? '-'}
                          </span>
                        </div>
                        {item.bundleTeamType === 'TEAM' && (
                          <div className="unit1">
                            {item.serveTeam?.teamName ?? '-'}
                            &nbsp;
                            {item.serveTeam?.teamPersonNum
                              ? '(' + item.serveTeam?.teamPersonNum + '人)'
                              : null}
                          </div>
                        )}
                        <div className="unit">
                          {item.bundleTeamType === 'TEAM'
                            ? item.serveTeam?.teamLeader?.institution
                                ?.institutionName ?? '-'
                            : item.serveDoctor?.institution?.institutionName ??
                              '-'}
                        </div>
                        <div className="profession">
                          {item.bundleTeamType === 'TEAM'
                            ? item.serveTeam?.teamLeader?.department
                                ?.departmentName ?? '-'
                            : item.serveDoctor?.department?.departmentName ??
                              '-'}
                        </div>
                      </div>
                      <div className="price">
                        {!experienceFlag && (
                          <>
                            <span className="unit">￥</span>
                            {isNumber(item.servePrice) ? (
                              <>
                                <span className="price_integer">
                                  {
                                    getIntegerAndDecimalNum(
                                      Math.floor(item.servePrice),
                                    ).integer
                                  }
                                  .
                                </span>
                                <span className="price_fractional">
                                  {
                                    getIntegerAndDecimalNum(item.servePrice)
                                      .decimal
                                  }
                                </span>
                              </>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })
              : renderEmpty()}
          </div>
        </div>
      </Popup>
    </>
  );
};

export default definePage(ServiceListPopupButton);
