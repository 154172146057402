import { createServices } from '@vs/vsf-boot';
export default createServices({
  RagPlanController_getPatientPlanTaskExecuteElement_552d18: {
    method: 'post',
    url: '/api/rag-plan-entrance-web/get-patient-plan-task-execute-element',
    parameterFomatter: (data?: { patientId: number; planMasterId: number }) =>
      data,
    responseFormatter: (_, __, data?: PlanTaskExecuteElementBaseVoDe76C9[]) =>
      data,
  },
  RagPlanController_getPatientIndicatorWithWarnInfo_aca4de: {
    method: 'post',
    url: '/api/rag-plan-entrance-web/get-patient-indicator-with-warn-info',
    parameterFomatter: (data?: {
      patientId: number;
      projectKey: string;
      planMasterId: number;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PlanIndicatorMonitoringWithWarnVoDe76C9,
    ) => data,
  },
  PlanController_queryMyReport_a13f6e: {
    method: 'post',
    url: '/api/rag-plan/query-my-report',
    parameterFomatter: (data?: { patientId: number; planMasterId: number }) =>
      data,
    responseFormatter: (_, __, data?: AbnormalIndicatorVoDe76C9) => data,
  },
});
